import styled, { css } from "styled-components";

import Small from "../../atoms/Typography/Small";

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
`;

export const Values = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 18fr));

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const Content = styled.div`
  gap: 12px;
  display: flex;
`;

type ODSIMageProps = {
  ods: string;
  size: string;
};

export const ODSImage = styled.div<ODSIMageProps>`
  ${({ ods, size }) => css`
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url(${ods});
    background-size: ${size};
    background-position: center;
    background-repeat: no-repeat;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 600px) {
      justify-self: center;
    }
  `}
`;

type IconProps = {
  borderColor: string;
  backgroundColor: string;
};

export const SelectedIcon = styled.div<IconProps>`
  ${({ borderColor, backgroundColor }) => css`
    width: 28px;
    height: 28px;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};

    svg {
      width: 80%;
      height: 80%;
    }
  `}
`;

export const Shadow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #00000080;
`;

export const ErrorMessage = styled(Small)`
  margin-top: 20px;
`;
