import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { IClients } from "../../types/clients";
import { Clients, Order } from "../../services";
import Page from "../../components/molecules/Page";
import Button from "../../components/atoms/Button";
import { filterTypeObj } from "../../constants/order";
import H3 from "../../components/atoms/Typography/H3";
import H4 from "../../components/atoms/Typography/H4";
import H5 from "../../components/atoms/Typography/H5";
import Skeleton from "../../components/atoms/Skeleton";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import { billingStatus, recurrence } from "../../utils/order";
import { IOrdersResponse, TFilterType } from "../../types/order";
import DropdownMenu from "../../components/organisms/DropdownMenu";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import { IDateRange, IMonths, TMonthsLabel } from "../../types/dates";
import TitleDescription from "../../components/molecules/TitleDescription";
import { emptyPeriod, getMonthNumber, getMonthRanges } from "../../utils/dates";

const validatePeriod = (data: IDateRange): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.inicio || data.inicio.length === 0)
    errors.inicio = ["Selecione um período"];

  if (!data.fim || data.fim.length === 0) errors.fim = ["Selecione um período"];

  if (data.inicio && data.fim) {
    const splittedInicio = data.inicio.split("/");
    const splittedFim = data.fim.split("/");
    const [inicioMonth, inicioYear] = [
      getMonthNumber(splittedInicio[0] as TMonthsLabel),
      +splittedInicio[1],
    ];
    const [fimMonth, fimYear] = [
      getMonthNumber(splittedFim[0] as TMonthsLabel),
      +splittedFim[1],
    ];

    if (
      inicioYear > fimYear ||
      (inicioYear === fimYear && inicioMonth > fimMonth)
    ) {
      errors.inicio = ["Início posterior ao fim"];
      errors.fim = ["Início posterior ao fim"];
    }
  }

  return errors;
};

const Statements: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [client, setClient] = useState<string>();
  const [months, setMonths] = useState<IMonths>();
  const [clients, setClients] = useState<IClients>();
  const [filtered, setFiltered] = useState<string[]>();
  const [month, setMonth] = useState<IDateRange>(emptyPeriod);
  const [statements, setStatements] = useState<IOrdersResponse>();
  const [filterType, setFilterType] = useState<TFilterType>("client");
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const { user, token } = Auth.useAuth();
  const { newError, newInfo } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const getClients = async () => {
      try {
        if (
          filterType === "client" &&
          (!clients || Object.keys(clients).length === 0)
        ) {
          const clientsData = await Clients.getClients(token);

          setClients(clientsData);
        } else if (
          filterType === "period" &&
          (!months || Object.keys(months).length === 0)
        ) {
          const monthsData = getMonthRanges(
            user.firstOrderDate || "",
            user.lastOrderDate || ""
          );

          setMonths(monthsData);
        }

        return;
      } catch (error) {
        newError("Houve um erro ao obter os dados para filtro");
      } finally {
        setLoading(false);
      }
    };

    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  useEffect(() => {
    if (ascendingOrder) {
      setStatements((curr) => {
        if (!curr) return;

        const currOrders = curr.orders;

        const sortedStatements = currOrders.sort((a, b) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);

          return aDate.getTime() - bDate.getTime();
        });

        return { ...curr, orders: sortedStatements };
      });

      return;
    }

    setStatements((curr) => {
      if (!curr) return;

      const currOrders = curr.orders;

      const sortedStatements = currOrders.sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);

        return bDate.getTime() - aDate.getTime();
      });

      return { ...curr, orders: sortedStatements };
    });
  }, [ascendingOrder]);

  const onGetClients = async () => {
    try {
      showLoading();

      if (!clients || Object.keys(clients).length === 0) {
        const clientsData = await Clients.getClients(token);

        setClients(clientsData);
      } else {
        newInfo(
          "Selecione um cliente e busque o extrato, ou atualize a página"
        );
      }
    } catch (error) {
      newError("Houve um erro ao obter os clients");
    } finally {
      hideLoading();
    }
  };

  const onFilterHandler = async () => {
    try {
      showLoading();

      if (
        filterType === "client" &&
        (!client || (client && client.length === 0))
      ) {
        newInfo("Selecione um cliente");
      } else if (filterType == "client" && client && client.length > 0) {
        const clientId = clients
          ? Object.keys(clients)[Object.values(clients).indexOf(client)]
          : "";

        const orders = await Order.getStatements(filterType, clientId, token);
        setStatements(orders);
      } else if (
        filterType === "period" &&
        (!month || (month.inicio.length === 0 && month.fim.length === 0))
      ) {
        newInfo("Selecione um período");
      } else if (
        filterType === "period" &&
        month.inicio.length > 0 &&
        month.fim.length > 0
      ) {
        const currErrors = validatePeriod(month);

        if (
          currErrors &&
          Object.keys(currErrors) &&
          Object.keys(currErrors).length > 0
        ) {
          setErrors(currErrors);
          newError("Verifique as datas selecionadas");

          return;
        }

        setErrors({});
        const period = `${
          months && months[month.inicio].inicio.replaceAll("/", "-")
        }_${months && months[month.fim].fim.replaceAll("/", "-")}`;
        const orders = await Order.getStatements(filterType, period, token);
        setStatements(orders);
      }
    } catch (error) {
      newError("Houve um erro ao obter as opções de filtro");
    } finally {
      hideLoading();
    }
  };

  const onSelectPeriod = (key: keyof IDateRange, value: string) => {
    setMonth((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onSelectFilterType = (value: string) => {
    const filterIndex = Object.values(filterTypeObj).indexOf(value);

    setFilterType(Object.keys(filterTypeObj)[filterIndex] as TFilterType);

    if (Object.keys(filterTypeObj)[filterIndex] === "client") {
      setMonth(emptyPeriod);
    } else if (Object.keys(filterTypeObj)[filterIndex] === "period") {
      if (!months || Object.keys(months).length === 0) {
        const monthsData = getMonthRanges(
          user.firstOrderDate || "",
          user.lastOrderDate || ""
        );

        setMonths(monthsData);
      }

      setClient(undefined);
    }
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const filterTypeOptions = Object.values(filterTypeObj);

  const hasOrders =
    user.firstOrderDate &&
    user.firstOrderDate.length > 0 &&
    user.lastOrderDate &&
    user.lastOrderDate.length > 0
      ? true
      : false;

  const dateTimeWidth = "20%";
  const statusWidth = "20%";
  const typeWidth = "20%";
  const tCo2Width = "20%";
  const incomeWidth = "20%";

  return (
    <Page pageIndex={1} mobileSection="statement">
      <S.Content>
        <TitleDescription
          title="Vendas"
          description="Com o extrato de vendas você analisa as informações de cada compensação feita por seus clientes."
        />
      </S.Content>

      {hasOrders ? (
        <>
          <S.Filters>
            <DropdownMenu
              label="Tipo"
              search={search}
              useSearch={true}
              labelWeight="bold"
              labelColor={textColor}
              bgColor={tertiaryColor}
              filteredOptions={filtered}
              openBgColor={backgroundColor}
              options={Object.values(filterTypeObj)}
              placeholder="Selecione o tipo do filtro"
              selected={filterTypeObj[filterType] || ""}
              onClearSearch={() => setSearch(undefined)}
              onSelect={(val) => onSelectFilterType(val)}
              onSearch={(val) => onSearch(val, filterTypeOptions || [""])}
            />

            {filterType === "period" && (
              <S.Period>
                <H5 color={textColor}>
                  O início sempre será o primeiro dia do mês selecionado. Já o
                  fim será o último dia do mês selecionado
                </H5>

                <S.PeriodFilter>
                  <DropdownMenu
                    label="Início"
                    search={search}
                    useSearch={true}
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.inicio}
                    bgColor={tertiaryColor}
                    selected={month.inicio}
                    filteredOptions={filtered}
                    openBgColor={backgroundColor}
                    placeholder="Selecione o período"
                    onClearSearch={() => setSearch(undefined)}
                    onSelect={(val) => onSelectPeriod("inicio", val)}
                    options={
                      months && Object.keys(months).length > 0
                        ? Object.keys(months)
                        : [""]
                    }
                    onSearch={(val) =>
                      onSearch(
                        val,
                        months && Object.keys(months).length > 0
                          ? Object.keys(months)
                          : [""]
                      )
                    }
                  />

                  <DropdownMenu
                    label="Fim"
                    search={search}
                    useSearch={true}
                    labelWeight="bold"
                    errors={errors.fim}
                    selected={month.fim}
                    labelColor={textColor}
                    bgColor={tertiaryColor}
                    filteredOptions={filtered}
                    openBgColor={backgroundColor}
                    placeholder="Selecione o período"
                    onClearSearch={() => setSearch(undefined)}
                    onSelect={(val) => onSelectPeriod("fim", val)}
                    options={
                      months && Object.keys(months).length > 0
                        ? Object.keys(months)
                        : [""]
                    }
                    onSearch={(val) =>
                      onSearch(
                        val,
                        months && Object.keys(months).length > 0
                          ? Object.keys(months)
                          : [""]
                      )
                    }
                  />
                </S.PeriodFilter>
              </S.Period>
            )}

            {!loading &&
              clients &&
              Object.values(clients).length > 0 &&
              filterType === "client" && (
                <DropdownMenu
                  label="Cliente"
                  search={search}
                  useSearch={true}
                  labelWeight="bold"
                  labelColor={textColor}
                  bgColor={tertiaryColor}
                  selected={client || ""}
                  filteredOptions={filtered}
                  openBgColor={backgroundColor}
                  placeholder="Selecione o cliente"
                  onSelect={(val) => setClient(val)}
                  onClearSearch={() => setSearch(undefined)}
                  options={
                    clients && Object.values(clients).length > 0
                      ? Object.values(clients)
                      : [""]
                  }
                  onSearch={(val) =>
                    onSearch(
                      val,
                      clients && Object.values(clients).length > 0
                        ? Object.values(clients)
                        : [""]
                    )
                  }
                />
              )}

            {!loading &&
              filterType === "client" &&
              (!clients || (clients && Object.keys(clients).length === 0)) && (
                <Button
                  variant="solid"
                  textColor={backgroundColor}
                  backgroundColor={primaryColor}
                  onClick={() => onGetClients()}
                >
                  Buscar clientes
                </Button>
              )}

            {loading && filterType === "client" && (
              <Skeleton
                direction="column"
                numberSkeletons={1}
                skeletonWidth="100%"
                skeletonHeight="40px"
              />
            )}
          </S.Filters>

          <S.GetOrdersButton>
            <Button
              variant="solid"
              textColor={backgroundColor}
              disabled={!client && !month}
              backgroundColor={primaryColor}
              onClick={() => onFilterHandler()}
            >
              Buscar vendas
            </Button>
          </S.GetOrdersButton>

          {!loading && statements && statements.numberOfOrders > 0 && (
            <S.Indicators>
              <S.IndicatorsBox>
                <S.Card backgroundColor={tertiaryColor}>
                  <H4 color={textColor} fontWeight="600">
                    Número de vendas
                  </H4>

                  <H3 color={textColor}>{statements.numberOfOrders}</H3>
                </S.Card>

                <S.Card backgroundColor={tertiaryColor}>
                  <H4 color={textColor} fontWeight="600">
                    Faturamento
                  </H4>

                  <H3 color={textColor}>
                    R${" "}
                    {statements.income.toLocaleString("pt-BR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </H3>
                </S.Card>
              </S.IndicatorsBox>

              <H5 color={`${textColor}80`}>
                *Os indicadores refletem os dados do filtro aplicado
              </H5>

              <S.Statements>
                <S.Header borderColor={tertiaryColor}>
                  <S.TitleCustomDate
                    fontWeight="bold"
                    color={textColor}
                    width={dateTimeWidth}
                  >
                    Data e hora
                    <S.SortIcon
                      isAscending={ascendingOrder}
                      onClick={() => setAscendingOrder((curr) => !curr)}
                    >
                      <AvailableIcons option="arrow" color={textColor} />
                    </S.SortIcon>
                  </S.TitleCustomDate>

                  <S.TitleCustom
                    fontWeight="bold"
                    color={textColor}
                    width={statusWidth}
                  >
                    Status
                  </S.TitleCustom>

                  {filterType === "client" ? (
                    <>
                      <S.TitleCustom
                        fontWeight="bold"
                        color={textColor}
                        width={typeWidth}
                      >
                        Tipo
                      </S.TitleCustom>

                      <S.TitleCustom
                        width={tCo2Width}
                        color={textColor}
                        fontWeight="bold"
                      >
                        tCO<sub>2</sub>
                      </S.TitleCustom>
                    </>
                  ) : (
                    <S.TitleCustom
                      width="40%"
                      fontWeight="bold"
                      color={textColor}
                    >
                      Cliente
                    </S.TitleCustom>
                  )}

                  <S.TitleCustom
                    width={incomeWidth}
                    color={textColor}
                    fontWeight="bold"
                  >
                    Receita
                  </S.TitleCustom>
                </S.Header>

                <S.StatementList>
                  {(statements || []).orders.map((item, index) => (
                    <S.Statement
                      key={`${item.id}#${index}`}
                      onClick={() => navigate(`/vendas/${item.id}`)}
                    >
                      <S.Icon>
                        <AvailableIcons option="statement" color={textColor} />
                      </S.Icon>

                      <S.TitleCustom width="18%" color={textColor}>
                        {new Date(item.createdAt).toLocaleString("pt-BR")}
                      </S.TitleCustom>

                      <S.TitleCustom width={statusWidth} color={textColor}>
                        {billingStatus(item.status)}
                      </S.TitleCustom>

                      {filterType === "client" ? (
                        <>
                          <S.TitleCustom width={tCo2Width} color={textColor}>
                            {recurrence(item.recurrence)}
                          </S.TitleCustom>

                          <S.TitleCustom width={tCo2Width} color={textColor}>
                            {item.credits.toLocaleString("pt-BR", {
                              minimumFractionDigits: 0,
                            })}
                          </S.TitleCustom>
                        </>
                      ) : (
                        <S.TitleCustom width="40%" color={textColor}>
                          {clients && clients[item.clientId]}
                        </S.TitleCustom>
                      )}

                      <S.TitleCustom width={incomeWidth} color={textColor}>
                        R${" "}
                        {item.totalValue.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                        })}
                      </S.TitleCustom>
                    </S.Statement>
                  ))}
                </S.StatementList>

                <S.StatementsCards>
                  {(statements || []).orders.map((item, index) => (
                    <S.StatementCard
                      key={`${item.id}#${index}`}
                      backgroundColor={tertiaryColor}
                      onClick={() => navigate(`/vendas/${item.id}`)}
                    >
                      <S.Icon>
                        <AvailableIcons option="statement" color={textColor} />
                      </S.Icon>

                      <S.StatementCardInfo>
                        <S.StatementCardText color={textColor}>
                          <span>Data e hora: </span>

                          {new Date(item.createdAt).toLocaleString("pt-BR")}
                        </S.StatementCardText>

                        <S.StatementCardText color={textColor}>
                          <span>Cliente: </span>

                          {clients && clients[item.clientId]}
                        </S.StatementCardText>

                        <S.StatementCardText color={textColor}>
                          <span>Status: </span>

                          {billingStatus(item.status)}
                        </S.StatementCardText>

                        <S.StatementCardText color={textColor}>
                          <span>Tipo: </span>

                          {recurrence(item.recurrence)}
                        </S.StatementCardText>

                        <S.StatementCardText color={textColor}>
                          <span>
                            tCO<sub>2</sub>:{" "}
                          </span>

                          {item.credits.toLocaleString("pt-BR", {
                            minimumFractionDigits: 0,
                          })}
                        </S.StatementCardText>

                        <S.StatementCardText color={textColor}>
                          <span>Receita: </span>
                          R$
                          {item.totalValue.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                          })}
                        </S.StatementCardText>
                      </S.StatementCardInfo>
                    </S.StatementCard>
                  ))}
                </S.StatementsCards>
              </S.Statements>
            </S.Indicators>
          )}
        </>
      ) : (
        <S.NoSellings>
          <H3 color={textColor}>
            Seus clientes ainda não realizaram nenhuma neutralização
          </H3>
        </S.NoSellings>
      )}
    </Page>
  );
};

export default Statements;
