import axios from "axios";

const { REACT_APP_CERTIFICATES_API_URL, REACT_APP_CERTIFICATES_BUCKET } =
  process.env;

const sendCertificate = async (
  clientId: string,
  year: string,
  token: string,
  file?: string
) => {
  try {
    if (!file) return;

    const { data } = await axios.post(
      `${REACT_APP_CERTIFICATES_API_URL}/offset-certificate/${clientId}/${year}`,
      file,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.certificates;
  } catch (error) {
    throw new Error("Erron on Certificates.sendCertificates");
  }
};

const getUploadURL = async (
  fileName: string,
  token: string
): Promise<string> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CERTIFICATES_API_URL}/presigned-url`,
      {
        fileName,
        bucket: REACT_APP_CERTIFICATES_BUCKET,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.url;
  } catch (error) {
    throw new Error("Houve um erro ao fazer o upload");
  }
};

export default {
  getUploadURL,
  sendCertificate,
};
