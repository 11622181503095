import axios from "axios";

import { IOrder, IOrdersResponse, TFilterType } from "../../types/order";

const { REACT_APP_ORDER_API_BASE_URL } = process.env;

const getStatements = async (
  filterType: TFilterType,
  filterOpt: string,
  token: string
): Promise<IOrdersResponse> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_ORDER_API_BASE_URL}/manager-statements/${filterType}/${filterOpt}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error("Houve um erro ao obter os dados do extrato.");
  }
};

const getStatement = async (
  orderId: string,
  token: string
): Promise<IOrder> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_ORDER_API_BASE_URL}/manager-statement/${orderId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.order;
  } catch (error) {
    throw new Error("Houve um erro ao obter os dados da order.");
  }
};

export default {
  getStatement,
  getStatements,
};
