import { QRCodeCanvas } from "qrcode.react";

import * as S from "./styles";
import {
  TBilling,
  TRecurrence,
  ICreditCard,
  TBillingStatus,
  ICreditCardHolderInfo,
} from "../../../types/order";
import { Theme } from "../../../hooks";
import { TIcon } from "../../../types";
import H4 from "../../atoms/Typography/H4";
import H5 from "../../atoms/Typography/H5";
import InputText from "../../molecules/InputText";
import AvailableIcons from "../../atoms/AvailableIcons";
import { paymentMethod } from "../../../constants/order";
import CreditCardsCard from "../../molecules/CreditCardsCard";
import { billingStatus, recurrence } from "../../../utils/order";

interface IProps {
  client: string;
  payment: TBilling;
  invoiceUrl?: string;
  billingValue: number;
  biggerTextarea?: boolean;
  copyIdentification?: string;
  paymentStatus: TBillingStatus;
  paymentRecurrence: TRecurrence;
  card?: {
    creditCard?: ICreditCard;
    creditCardToken?: string;
    creditCardHolderInfo?: ICreditCardHolderInfo;
  };
}

interface ITopics {
  icon: TIcon;
  label: string;
  value: string;
}

const PaymentDetails: React.FC<IProps> = ({
  card,
  client,
  payment,
  invoiceUrl,
  billingValue,
  paymentStatus,
  biggerTextarea,
  paymentRecurrence,
  copyIdentification,
}) => {
  const { primaryColor, backgroundColor, tertiaryColor, textColor } =
    Theme.useTheme();

  const content = (() => {
    if (payment === "PIX" && paymentStatus === "PENDING") {
      return (
        <S.PaymentMethod>
          <S.QRCode>
            <QRCodeCanvas value={copyIdentification || ""} size={200} />
          </S.QRCode>

          <S.CopyCode>
            <InputText
              baseURL=""
              as="textarea"
              copyIcon={true}
              color={textColor}
              onChange={() => null}
              rows={biggerTextarea ? 7 : 5}
              value={copyIdentification || ""}
              label="Clique aqui e copie o código PIX"
            />
          </S.CopyCode>
        </S.PaymentMethod>
      );
    }

    if (payment === "BOLETO" && paymentStatus === "PENDING") {
      return (
        <S.PaymentMethod>
          <S.Invoice
            target="_blank"
            href={invoiceUrl}
            rel="noreferrer noopener"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
          >
            <AvailableIcons option="download" color={backgroundColor} />
            Visualize o boleto
          </S.Invoice>

          <S.CopyCode>
            <InputText
              baseURL=""
              as="textarea"
              copyIcon={true}
              color={textColor}
              onChange={() => null}
              rows={biggerTextarea ? 2 : 1}
              value={copyIdentification || ""}
              label="Clique aqui e copie o código de barras do boleto"
            />
          </S.CopyCode>
        </S.PaymentMethod>
      );
    }

    if (payment === "CREDIT_CARD") {
      return (
        <S.PaymentMethod>
          <H4 color={textColor}>Cartão de crédito utilizado:</H4>

          <S.Card>
            <CreditCardsCard
              isSelected={true}
              onSelect={() => null}
              number={(card && card.creditCard?.number) || ""}
              name={(card && card.creditCard?.holderName) || ""}
              brand={(card && card.creditCard?.brand) || "mastercard"}
            />
          </S.Card>
        </S.PaymentMethod>
      );
    }
  })();

  const statusIcon = (status: TBillingStatus): TIcon => {
    if (
      status === "RECEIVED" ||
      status === "CONFIRMED" ||
      status === "RECEIVED_IN_CASH"
    )
      return "check";

    return "infoOne";
  };

  const paymentIcon = (type: TBilling): TIcon => {
    if (type === "PIX") return "pix";

    if (type === "BOLETO") return "barCode";

    return "card";
  };

  const summaryTopics: ITopics[] = [
    {
      label: "Status",
      icon: statusIcon(paymentStatus),
      value: billingStatus(paymentStatus),
    },
    {
      icon: "person",
      value: client,
      label: "Cliente",
    },
    {
      icon: "loading",
      label: "Tipo da compra",
      value: recurrence(paymentRecurrence),
    },
    {
      icon: "money",
      label: "Valor da cobrança",
      value: `R$ ${billingValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      })}`,
    },
    {
      icon: paymentIcon(payment),
      label: "Método de pagamento",
      value: paymentMethod[payment],
    },
  ];

  return (
    <S.PaymentDetails>
      <S.Summary>
        {summaryTopics.map((item, index) => (
          <S.IconAndDetail key={`${item.icon}#${index}`}>
            <S.IconAndText backgroundColor={tertiaryColor}>
              <S.Icon>
                <AvailableIcons option={item.icon} color={textColor} />
              </S.Icon>

              <H5 color={textColor} fontWeight="bold">
                {item.label}
              </H5>
            </S.IconAndText>

            <H5 color={textColor}>{item.value}</H5>
          </S.IconAndDetail>
        ))}
      </S.Summary>

      <S.Content>{content}</S.Content>
    </S.PaymentDetails>
  );
};

export default PaymentDetails;
