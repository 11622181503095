import axios from "axios";

import { IProject } from "../../types/projects";
import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_PROJECTS_API_URL } = process.env;

const getProjects = async (
  entity: string,
  token: string
): Promise<IProject[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/manager-projects/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.projects;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os projetos disponíveis");
  }
};

const getProject = async (
  entity: string,
  id: string,
  token: string
): Promise<IProject> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/manager-project/${entity}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.project;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os dados do projeto");
  }
};

const addProject = async (entity: string, project: IProject, token: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_PROJECTS_API_URL}/add-project`,
      { entity, project },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.projects;
  } catch (error) {
    throw new Error("Houve um erro ao adicionar o projeto");
  }
};

const updateProject = async (
  entity: string,
  project: IProject,
  token: string
) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_PROJECTS_API_URL}/update-project`,
      { entity, project },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.projects;
  } catch (error) {
    throw new Error("Houve um erro ao atualizar os dados do projeto");
  }
};

const removeProject = async (
  entity: string,
  projectId: string,
  token: string
) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_PROJECTS_API_URL}/remove/${entity}/${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.projects;
  } catch (error) {
    throw new Error("Houve um erro ao adicionar o projeto");
  }
};

export default {
  addProject,
  getProject,
  getProjects,
  updateProject,
  removeProject,
};
