import { IDateRange, TMonths, TMonthsLabel } from "../types/dates";

export const normalizeDateToLocale = (date: string) => {
  const parsedDate = new Date(date).toLocaleString("pt-BR");

  const [normalizedDate, time] = parsedDate.split(" ");

  const normalizedTime = time.substring(0, 5);

  return `${normalizedDate} às ${normalizedTime}`;
};

export const maskHourAndMinute = (value: string) => {
  if (!value) return "";

  const onlyNumbers = value.replace(/[^0-9]/g, "");

  if (onlyNumbers.length <= 2) return onlyNumbers;

  const firstPart = onlyNumbers.substring(0, 2);
  const secondPart = onlyNumbers.substring(2);

  return `${firstPart}:${secondPart}`;
};

export const maskDate = (value: string) => {
  if (!value) return "";

  if (value.length > 8) {
    const day = value.substring(0, 2);
    const month = value.substring(2, 4);
    const year = value.substring(4, 8);

    return `${day}/${month}/${year}`;
  }

  const day = value.substring(0, 2);
  const month = value.substring(2, 4);
  const year = value.substring(4);

  if (!month) return `${day}`;

  if (!year) return `${day}/${month}`;

  return `${day}/${month}/${year}`;
};

export const getMonthLabel = (month: TMonths): string => {
  switch (month) {
    case 0:
      return "Janeiro";
    case 1:
      return "Fevereiro";
    case 2:
      return "Março";
    case 3:
      return "Abril";
    case 4:
      return "Maio";
    case 5:
      return "Junho";
    case 6:
      return "Julho";
    case 7:
      return "Agosto";
    case 8:
      return "Setembro";
    case 9:
      return "Outubro";
    case 10:
      return "Novembro";
    case 11:
      return "Dezembro";
  }
};

export const getMonthNumber = (month: TMonthsLabel): number => {
  switch (month) {
    case "Janeiro":
      return 0;
    case "Fevereiro":
      return 1;
    case "Março":
      return 2;
    case "Abril":
      return 3;
    case "Maio":
      return 4;
    case "Junho":
      return 5;
    case "Julho":
      return 6;
    case "Agosto":
      return 7;
    case "Setembro":
      return 8;
    case "Outubro":
      return 9;
    case "Novembro":
      return 10;
    case "Dezembro":
      return 11;
    default:
      return -1;
  }
};

// Função para calcular a diferença em meses entre duas datas
const getMonthDiff = (date1: Date, date2: Date): number => {
  return (
    (date2.getFullYear() - date1.getFullYear()) * 12 +
    date2.getMonth() -
    date1.getMonth()
  );
};

// Função para obter o objeto desejado com os intervalos de início e fim para cada mês
export const getMonthRanges = (
  firstOrderDate: string,
  lastOrderDate: string
): Record<string, IDateRange> => {
  if (
    !firstOrderDate ||
    (firstOrderDate && firstOrderDate.length === 0) ||
    !lastOrderDate ||
    (lastOrderDate && lastOrderDate.length === 0)
  )
    return { "Sem vendas": { inicio: "", fim: "" } };

  const startDate = new Date(firstOrderDate);
  const endDate = new Date(lastOrderDate);

  const monthDiff = getMonthDiff(startDate, endDate);

  const result: Record<string, IDateRange> = {};

  for (let i = 0; i <= monthDiff; i++) {
    const currentDate = new Date(startDate);
    currentDate.setMonth(startDate.getMonth() + i);

    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const monthLabel = startOfMonth.toLocaleString("pt-BR", {
      month: "long",
    });

    result[
      `${
        monthLabel.charAt(0).toUpperCase() + monthLabel.slice(1)
      }/${startOfMonth.getFullYear()}`
    ] = {
      inicio: startOfMonth.toLocaleDateString("pt-BR"),
      fim: endOfMonth.toLocaleDateString("pt-BR"),
    };
  }

  return result;
};

export const emptyPeriod: IDateRange = {
  inicio: "",
  fim: "",
};
