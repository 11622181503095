import * as S from "./styles";
import { Theme } from "../../../hooks";
import { TODS } from "../../../types/projects";
import { ODS } from "../../../constants/projects";
import AvailableIcons from "../../atoms/AvailableIcons";

const { REACT_APP_ODS_BASE_URL } = process.env;

interface IProps {
  selected: TODS[];
  errors?: string[];
  onSelected: (value: string) => void;
}

const ODSSelector: React.FC<IProps> = ({ errors, selected, onSelected }) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.Values>
        {ODS.sort((a, b) => +a - +b).map((item) => (
          <S.Content key={item}>
            <S.ODSImage
              key={item}
              size="cover"
              ods={`${REACT_APP_ODS_BASE_URL}/ods-${item}.svg`}
            >
              {!selected.includes(item) && <S.Shadow />}
            </S.ODSImage>

            <S.SelectedIcon
              borderColor={primaryColor}
              onClick={() => onSelected(item)}
              backgroundColor={
                selected.includes(item) ? primaryColor : backgroundColor
              }
            >
              <AvailableIcons option="check" color={backgroundColor} />
            </S.SelectedIcon>
          </S.Content>
        ))}

        <S.ODSImage
          size="contain"
          ods={`${REACT_APP_ODS_BASE_URL}/ods-logo.jpg`}
        />
      </S.Values>

      {errors && errors.length ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default ODSSelector;
