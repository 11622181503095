import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import {
  TODS,
  IProject,
  TStorage,
  IImpactTopics,
  TOffsetIssuer,
} from "../../../../types/projects";
import Button from "../../../../components/atoms/Button";
import Page from "../../../../components/molecules/Page";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H3 from "../../../../components/atoms/Typography/H3";
import H4 from "../../../../components/atoms/Typography/H4";
import { normalizeNumber } from "../../../../utils/numbers";
import Skeleton from "../../../../components/atoms/Skeleton";
import { Project as ProjectService } from "../../../../services";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import InputText from "../../../../components/molecules/InputText";
import AddImages from "../../../../components/organisms/AddImages";
import ODSSelector from "../../../../components/organisms/ODSSelector";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import DropdownMenu from "../../../../components/organisms/DropdownMenu";
import ExcludeModal from "../../../../components/molecules/ExcludeModal";
import InputNumberBase from "../../../../components/atoms/InputNumberBase";
import { offsetIssuer, storageOptions } from "../../../../constants/projects";
import AddProjectImpact from "../../../../components/organisms/AddProjectImpact";
import AddProjectDescription from "../../../../components/organisms/AddProjectDescription";
import DiscardProjectsChangesModal from "../../../../components/molecules/DiscardProjectChangesModal";
import ExcludeProjectModal from "../../../../components/molecules/ExcludeProjectModal";

const validateProject = (data: IProject) => {
  const errors: { [key: string]: string[] } = {};

  if (!data.name || data.name.length === 0)
    errors.name = [...(errors.name || []), "Nome do projeto é obrigatório"];

  if (!data.cardImage || data.cardImage.length === 0)
    errors.cardImage = [
      ...(errors.cardImage || []),
      "Imagem de fundo do card é obrigatório",
    ];

  if (!data.label || Object.keys(data.label).length === 0)
    errors.label = [...(errors.label || []), "Label para o card é obrigatório"];

  if (!data.shortDescription || Object.keys(data.shortDescription).length === 0)
    errors.shortDescription = [
      ...(errors.shortDescription || []),
      "Descrição curta para o card é obrigatório",
    ];

  if (data.shortDescription && Object.keys(data.shortDescription).length > 0) {
    Object.keys(data.shortDescription).map((item) => {
      if (
        !data.shortDescription[item] ||
        data.shortDescription[item].length === 0
      ) {
        errors[`shortDescription#${item}`] = [
          ...(errors[`shortDescription#${item}`] || []),
          "Descrição curta para o card é obrigatório",
        ];
      } else if (
        data.shortDescription[item].length > 0 &&
        data.shortDescription[item].replace(/[^a-zA-Z0-9]/g, "").length === 0
      ) {
        errors[`shortDescription#${item}`] = [
          ...(errors[`shortDescription#${item}`] || []),
          "Insira uma descrição válida",
        ];
      }
    });
  }

  if (data.description) {
    const indexes = data.description.reduce((acc: number[], curr) => {
      if (!curr.value || curr.value.length === 0) {
        return [...acc, curr.index];
      }

      return acc;
    }, []);

    if (indexes && indexes.length > 0) {
      indexes.map((item) => {
        errors[`description#${item}`] = [
          ...(errors[`description#${item}`] || []),
          "Descrição é obrigatório",
        ];
      });
    }
  }

  if (!data.impactSummary.summary || data.impactSummary.summary.length === 0)
    errors.summary = [
      ...(errors.summary || []),
      "Resumo dos impactos é obrigatório",
    ];

  if (!data.ods || data.ods.length === 0)
    errors.ods = [...(errors.ods || []), "ODS é obrigatório"];

  if (data.impactSummary.topics) {
    data.impactSummary.topics.map((item) => {
      if (!item.value || item.value.length === 0) {
        errors[`value#${item.index}`] = [
          ...(errors[`value#${item.index}`] || []),
          "Descrição do impacto é obrigatório",
        ];
      }

      if (!item.title || item.title.length === 0) {
        errors[`title#${item.index}`] = [
          ...(errors[`title#${item.index}`] || []),
          "Título do impacto é obrigatório",
        ];
      }
    });
  }

  return errors;
};

const Project: React.FC = () => {
  const { id } = useParams();

  const triggerButton = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [back, setBack] = useState<boolean>(false);
  const [project, setProject] = useState<IProject>();
  const [excludeImpact, setExcludeImpact] = useState<number>(-1);
  const [updatedProject, setUpdatedProject] = useState<IProject>();
  const [excludeProject, setExcludeProject] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [changedSettings, setChangedSettings] = useState<boolean>(false);
  const [excludeDescription, setExcludeDescription] = useState<number>(-1);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const projectData = await ProjectService.getProject(
          user.entity,
          id || "",
          token
        );

        const normPricing = projectData.details.pricing * 100;

        const normProject = {
          ...projectData,
          details: { ...projectData.details, pricing: normPricing },
        };

        setProject(normProject);
        setUpdatedProject(normProject);
      } catch (error) {
        newError("Houve um erro ao obter as informações do projeto");
      } finally {
        setLoading(false);
      }
    };

    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition =
        triggerButton.current?.getBoundingClientRect().bottom || 0;

      setIsFixed(scrollPosition <= triggerPosition + 2000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(updatedProject) !== JSON.stringify(project)) {
      setChangedSettings(true);

      return;
    }

    setChangedSettings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProject]);

  const onBackHandler = () => {
    if (changedSettings && !back) {
      setBack(true);

      return;
    }

    if (back) {
      setBack(false);
    }

    navigate("/projetos");
  };

  const onChangeProjectCard = (
    key: "label" | "shortDescription",
    language: string,
    value: string
  ) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      if (key === "label") {
        return {
          ...curr,
          label: {
            ...curr.label,
            [language]: value,
          },
        };
      }

      return {
        ...curr,
        shortDescription: {
          ...curr.shortDescription,
          [language]: value,
        },
      };
    });
  };

  const onChangeProject = (key: keyof IProject, value: string) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      if (key === "creditQuantity") {
        return {
          ...curr,
          creditQuantity: +value.replace(/[^0-9]/g, ""),
        };
      }

      if (key === "ods") {
        if (curr.ods.includes(value as TODS))
          return {
            ...curr,
            ods: [...curr.ods.filter((item) => item !== value)],
          };

        return {
          ...curr,
          ods: [...curr.ods, value as TODS],
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onChangeProjectDetails = (
    key: keyof IProject["details"],
    value: string
  ) => {
    if (!updatedProject?.details) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      if (key === "pricing") {
        return {
          ...curr,
          details: {
            ...curr.details,
            [key]: +(value || "").replace(/[^0-9]/g, ""),
          },
        };
      }

      if (key === "storage") {
        return {
          ...curr,
          details: {
            ...curr.details,
            [key]: storageOptions[value] as TStorage,
          },
        };
      }

      return {
        ...curr,
        details: {
          ...curr.details,
          [key]: value.toString() as TOffsetIssuer,
        },
      };
    });
  };

  const onChangeDescription = (index: number, value: string) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      const newDescription = updatedProject.description.map((item) => {
        if (item.index !== index) return item;

        return {
          ...item,
          value: value,
        };
      });

      return {
        ...curr,
        description: newDescription,
      };
    });
  };

  const onAddDescription = () => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      return {
        ...curr,
        description: [
          ...(curr?.description || []),
          { index: curr.description.length || 0, value: "" },
        ],
      };
    });
  };

  const onRemoveDescription = (index: number) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      const filteredDescription = curr.description.filter(
        (item) => item.index !== index
      );

      const normFilteredDescription = filteredDescription.map((item) => {
        if (item.index < index) return item;

        return {
          ...item,
          index: item.index - 1,
        };
      });

      return {
        ...curr,
        description: normFilteredDescription,
      };
    });

    setExcludeDescription(-1);
  };

  const onChangeSummary = (value: string) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      return {
        ...curr,
        impactSummary: {
          ...curr.impactSummary,
          summary: value,
        },
      };
    });
  };

  const onChangeImpacts = (
    index: number,
    key: keyof IImpactTopics,
    value: string
  ) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      const newTopics = updatedProject.impactSummary.topics.map((item) => {
        if (item.index !== index) return item;

        return {
          ...item,
          [key]: value,
        };
      });

      return {
        ...curr,
        impactSummary: {
          ...curr.impactSummary,
          topics: newTopics,
        },
      };
    });
  };

  const onAddImpact = () => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      return {
        ...curr,
        impactSummary: {
          ...curr?.impactSummary,
          topics: [
            ...(curr.impactSummary.topics || []),
            {
              value: "",
              image: "",
              title: "",
              index: curr.impactSummary.topics.length || 0,
            },
          ],
        },
      };
    });
  };

  const onRemoveImpact = (index: number) => {
    if (!updatedProject) return;

    setUpdatedProject((curr) => {
      if (!curr) return;

      const filteredTopics = curr.impactSummary.topics.filter(
        (item) => item.index !== index
      );

      const normFilteredTopics = filteredTopics.map((item) => {
        if (item.index < index) return item;

        return {
          ...item,
          index: item.index - 1,
        };
      });

      return {
        ...curr,
        impactSummary: {
          ...curr?.impactSummary,
          topics: normFilteredTopics,
        },
      };
    });

    setExcludeImpact(-1);
  };

  const onSaveProject = async () => {
    try {
      showLoading();

      if (!updatedProject) return;

      const currErrors = validateProject(updatedProject);

      if (currErrors && Object.keys(currErrors).length > 0) {
        setErrors(currErrors);

        return;
      }

      setErrors({});

      const payload: IProject = {
        ...updatedProject,
        details: {
          ...updatedProject?.details,
          pricing: (updatedProject?.details.pricing || 0) / 100,
        },
      };

      await ProjectService.updateProject(user.entity, payload, token);

      navigate("/projetos");
    } catch (error) {
      newError("Houve um erro ao atualizar os dados do projeto");
    } finally {
      hideLoading();
    }
  };

  const onExcludeProject = async () => {
    try {
      showLoading();

      await ProjectService.removeProject(user.entity, project?.id || "", token);

      setExcludeProject(false);
      navigate("/projetos");
    } catch (error) {
      newError("Houve um erro ao excluir o projeto");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page pageIndex={0} mobileSection="projects">
      <S.Container>
        <S.Header>
          <Button
            size="small"
            variant="backButton"
            backgroundColor={tertiaryColor}
            onClick={() => onBackHandler()}
          >
            <AvailableIcons option="chevron" color={textColor} />
          </Button>
        </S.Header>

        {loading ? (
          <Skeleton
            direction="column"
            numberSkeletons={10}
            skeletonWidth="100%"
            skeletonHeight="40px"
          />
        ) : (
          <>
            <S.CardEdit>
              <H1 color={textColor} fontWeight="bold">
                Informações do card do projeto
              </H1>

              <AddImages
                size="cover"
                height="320px"
                showPreview={true}
                label="Imagem de fundo"
                errors={errors.cardImage}
                image={updatedProject?.cardImage || ""}
                onChange={(val) => onChangeProject("cardImage", val)}
              />

              <InputText
                errors={errors.label}
                label="Label (Português)"
                value={updatedProject?.label["pt"] || ""}
                onChange={(val) => onChangeProjectCard("label", "pt", val)}
              />

              <InputText
                label="Label (Inglês)"
                errors={errors.label}
                value={updatedProject?.label["en"] || ""}
                onChange={(val) => onChangeProjectCard("label", "en", val)}
              />

              <InputText
                limit={true}
                charLimit={200}
                label="Descrição curta (Português)"
                errors={errors["shortDescription#pt"]}
                value={updatedProject?.shortDescription["pt"] || ""}
                onChange={(val) =>
                  onChangeProjectCard("shortDescription", "pt", val)
                }
              />

              <InputText
                limit={true}
                charLimit={200}
                label="Descrição curta (Inglês)"
                errors={errors["shortDescription#en"]}
                value={updatedProject?.shortDescription["en"] || ""}
                onChange={(val) =>
                  onChangeProjectCard("shortDescription", "en", val)
                }
              />
            </S.CardEdit>

            <S.ProjectEdit borderColor={tertiaryColor}>
              <H1 color={textColor} fontWeight="bold">
                Informações da página do projeto
              </H1>

              <S.ProjectName>
                <InputText
                  errors={errors.name}
                  label="Nome do projeto"
                  value={updatedProject?.name || ""}
                  onChange={(val) => onChangeProject("name", val)}
                />
              </S.ProjectName>

              <S.Details>
                <S.Box key="pricing">
                  <H4 color={textColor}>Preço</H4>

                  <S.Detail backgroundColor={tertiaryColor}>
                    <S.DetailIcon>
                      <AvailableIcons option="money" color={textColor} />
                    </S.DetailIcon>

                    <InputNumberBase
                      type="R$"
                      value={normalizeNumber(
                        updatedProject?.details.pricing || 0,
                        "R$"
                      )}
                      onChange={(val) => onChangeProjectDetails("pricing", val)}
                    />
                  </S.Detail>
                </S.Box>

                <S.Box key="credits">
                  <H4 color={textColor}>Créditos disponíveis</H4>

                  <S.Detail backgroundColor={tertiaryColor} width="200px">
                    <S.DetailIcon>
                      <AvailableIcons option="seal" color={textColor} />
                    </S.DetailIcon>

                    <InputNumberBase
                      type="number"
                      value={normalizeNumber(
                        updatedProject?.creditQuantity || 0,
                        "number"
                      )}
                      onChange={(val) => onChangeProject("creditQuantity", val)}
                    />
                  </S.Detail>
                </S.Box>

                <S.Box key="storage">
                  <H4 color={textColor}>Storage</H4>

                  <S.Detail backgroundColor={tertiaryColor} width="280px">
                    <S.DetailIcon>
                      <AvailableIcons option="storage" color={textColor} />
                    </S.DetailIcon>

                    <DropdownMenu
                      label=""
                      placeholder=""
                      labelWeight="400"
                      borderColor={tertiaryColor}
                      openBgColor={backgroundColor}
                      options={Object.keys(storageOptions)}
                      onSelect={(val) => onChangeProjectDetails("storage", val)}
                      selected={
                        `${updatedProject?.details.storage} storage` ||
                        Object.keys(storageOptions)[0]
                      }
                    />
                  </S.Detail>
                </S.Box>

                <S.Box key="offsetIssuer">
                  <H4 color={textColor}>Certificadora</H4>

                  <S.Detail backgroundColor={tertiaryColor} width="300px">
                    <S.DetailIcon>
                      <AvailableIcons option="certified" color={textColor} />
                    </S.DetailIcon>

                    <DropdownMenu
                      label=""
                      placeholder=""
                      labelWeight="400"
                      options={offsetIssuer}
                      borderColor={tertiaryColor}
                      openBgColor={backgroundColor}
                      selected={updatedProject?.details.certifier || ""}
                      onSelect={(val) =>
                        onChangeProjectDetails("certifier", val)
                      }
                    />
                  </S.Detail>
                </S.Box>
              </S.Details>

              <S.Description>
                <H3 color={textColor} fontWeight="bold">
                  Descrição do projeto
                </H3>

                <AddProjectDescription
                  errors={errors}
                  buttonLabel="Adicionar parágrafo"
                  addValues={() => onAddDescription()}
                  values={
                    updatedProject?.description.length
                      ? updatedProject?.description
                      : undefined
                  }
                  removeValues={(index) => setExcludeDescription(index)}
                  changeValues={(index, val) => onChangeDescription(index, val)}
                />
              </S.Description>

              <S.Summary borderColor={tertiaryColor}>
                <H3 color={textColor} fontWeight="bold">
                  Impactos do projeto
                </H3>

                <InputText
                  limit={true}
                  as="textarea"
                  charLimit={1000}
                  errors={errors.summary}
                  label="Resumo dos impactos"
                  onChange={(val) => onChangeSummary(val)}
                  value={updatedProject?.impactSummary.summary || ""}
                />

                <AddProjectImpact
                  errors={errors}
                  addValues={() => onAddImpact()}
                  buttonLabel="Adicionar impacto"
                  values={updatedProject?.impactSummary.topics}
                  removeValues={(index) => setExcludeImpact(index)}
                  changeValues={(index, key, val) =>
                    onChangeImpacts(index, key, val)
                  }
                />
              </S.Summary>

              <S.ODS ref={triggerButton}>
                <H3 color={textColor} fontWeight="bold">
                  Objetivos de Desenvolvimento Sustentável da ONU
                </H3>

                <P color={textColor}>
                  Estes são os ODS da ONU que o projeto apoia.
                </P>

                <ODSSelector
                  errors={errors.ods}
                  onSelected={(val) => onChangeProject("ods", val)}
                  selected={(updatedProject && updatedProject.ods) || ["1"]}
                />
              </S.ODS>
            </S.ProjectEdit>
          </>
        )}

        <S.SaveButton
          variant="solid"
          fontWeight="600"
          textColor={backgroundColor}
          disabled={!changedSettings}
          hasChanged={changedSettings}
          onClick={() => onSaveProject()}
          fixed={isFixed && changedSettings}
          backgroundColor={changedSettings ? primaryColor : `${primaryColor}40`}
        >
          Salvar alterações
        </S.SaveButton>

        <Button
          fontWeight="600"
          variant="outline"
          textColor="#ff0000"
          borderColor="#ff0000"
          onClick={() => setExcludeProject(true)}
        >
          Excluir projeto
        </Button>

        {excludeDescription >= 0 && (
          <ExcludeModal
            isOpen={excludeDescription >= 0}
            onClose={() => setExcludeDescription(-1)}
            onExclude={() => onRemoveDescription(excludeDescription)}
          />
        )}

        {excludeImpact >= 0 && (
          <ExcludeModal
            isOpen={excludeImpact >= 0}
            onClose={() => setExcludeImpact(-1)}
            onExclude={() => onRemoveImpact(excludeImpact)}
          />
        )}

        {excludeProject && (
          <ExcludeProjectModal
            isOpen={excludeProject}
            onExclude={() => onExcludeProject()}
            onClose={() => setExcludeProject(false)}
          />
        )}

        {back && (
          <DiscardProjectsChangesModal
            isOpen={back}
            onClose={() => setBack(false)}
            onExclude={() => onBackHandler()}
          />
        )}
      </S.Container>
    </Page>
  );
};

export default Project;
