import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
`;

export const Values = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  gap: 28px;
  display: flex;
`;

export const Inputs = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    gap: 20px;
  }
`;

export const AddButton = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
`;

export const RemoveIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
