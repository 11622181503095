import { ReactElement } from "react";

import { TIcon } from "../../../types";

import PixIcon from "../../icons/PixIcon";
import Chevron from "../../icons/Chevron";
import AgroIcon from "../../icons/AgroIcon";
import PlusIcon from "../../icons/PlusIcon";
import CopyIcon from "../../icons/CopyIcon";
import SealIcon from "../../icons/SealIcon";
import CloseIcon from "../../icons/CloseIcon";
import GlobeIcon from "../../icons/GlobeIcon";
import CheckIcon from "../../icons/CheckIcon";
import ImageIcon from "../../icons/ImageIcon";
import MoneyIcon from "../../icons/MoneyIcon";
import EventsIcon from "../../icons/EventsIcon";
import EngineIcon from "../../icons/EngineIcon";
import FolderIcon from "../../icons/FolderIcon";
import ReportIcon from "../../icons/ReportIcon";
import GoogleIcon from "../../icons/GoogleIcon";
import PersonIcon from "../../icons/PersonIcon";
import BarCodeIcon from "../../icons/BarCodeIcon";
import InfoOneIcon from "../../icons/InfoOneIcon";
import StorageIcon from "../../icons/StorageIcon";
import LogisticIcon from "../../icons/LogisticIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import TrashCanIcon from "../../icons/TrashCanIcon";
import AnalyticsIcon from "../../icons/AnalyticsIcon";
import CertifiedIcon from "../../icons/CertifiedIcon";
import MegaphoneIcon from "../../icons/MegaphoneIcon";
import StatementIcon from "../../icons/StatementIcon";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import FacilitiesIcon from "../../icons/FacilitiesIcon";
import CalculatorIcon from "../../icons/CalculatorIcon";
import CreditCardIcon from "../../icons/CreditCardIcon";
import StaticLoadingIcon from "../../icons/StaticLoadingIcon";
import IdentificationIcon from "../../icons/IdentificationIcon";

interface IProps {
  option: TIcon;
  color?: string;
}

const AvailableIcons: React.FC<IProps> = ({ option, color }) => {
  const icons: { [key in TIcon]: ReactElement } = {
    pix: <PixIcon color={color} />,
    agro: <AgroIcon color={color} />,
    plus: <PlusIcon color={color} />,
    copy: <CopyIcon color={color} />,
    seal: <SealIcon color={color} />,
    check: <CheckIcon color={color} />,
    close: <CloseIcon color={color} />,
    chevron: <Chevron color={color} />,
    globe: <GlobeIcon color={color} />,
    image: <ImageIcon color={color} />,
    money: <MoneyIcon color={color} />,
    person: <PersonIcon color={color} />,
    engine: <EngineIcon color={color} />,
    folder: <FolderIcon color={color} />,
    report: <ReportIcon color={color} />,
    google: <GoogleIcon color={color} />,
    events: <EventsIcon color={color} />,
    storage: <StorageIcon color={color} />,
    barCode: <BarCodeIcon color={color} />,
    card: <CreditCardIcon color={color} />,
    infoOne: <InfoOneIcon color={color} />,
    arrow: <ArrowLeftIcon color={color} />,
    trashCan: <TrashCanIcon color={color} />,
    logistic: <LogisticIcon color={color} />,
    download: <DownloadIcon color={color} />,
    document: <DocumentIcon color={color} />,
    certified: <CertifiedIcon color={color} />,
    analytics: <AnalyticsIcon color={color} />,
    megaphone: <MegaphoneIcon color={color} />,
    statement: <StatementIcon color={color} />,
    loading: <StaticLoadingIcon color={color} />,
    facilities: <FacilitiesIcon color={color} />,
    calculator: <CalculatorIcon color={color} />,
    identification: <IdentificationIcon color={color} />,
  };

  return icons[option] || null;
};

export default AvailableIcons;
