import * as S from "./styles";
import AddImages from "../AddImages";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import InputText from "../../molecules/InputText";
import { IImpactTopics } from "../../../types/projects";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  buttonLabel: string;
  addValues: () => void;
  values?: IImpactTopics[];
  errors?: { [key: string]: string[] };
  removeValues: (index: number) => void;
  changeValues: (
    index: number,
    key: keyof IImpactTopics,
    value: string
  ) => void;
}

const AddProjectImpact: React.FC<IProps> = ({
  values,
  errors,
  addValues,
  buttonLabel,
  changeValues,
  removeValues,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.Values>
        {values &&
          values.length > 0 &&
          values
            .sort((a, b) => a.index - b.index)
            .map((item) => (
              <S.Content key={item.index}>
                <S.Inputs>
                  <H4 color={textColor} fontWeight="600">
                    Impacto {item.index + 1}
                  </H4>

                  <InputText
                    limit={true}
                    as="textarea"
                    label="Título"
                    charLimit={140}
                    labelWeight="500"
                    value={item.title || ""}
                    errors={errors && errors[`title#${item.index}`]}
                    onChange={(val) => changeValues(item.index, "title", val)}
                  />

                  <InputText
                    limit={true}
                    as="textarea"
                    charLimit={1000}
                    label="Descrição"
                    labelWeight="500"
                    value={item.value || ""}
                    errors={errors && errors[`value#${item.index}`]}
                    onChange={(val) => changeValues(item.index, "value", val)}
                  />

                  <AddImages
                    showPreview={true}
                    image={item.image || ""}
                    onChange={(val) => changeValues(item.index, "image", val)}
                  />
                </S.Inputs>

                <S.RemoveIcon onClick={() => removeValues(item.index)}>
                  <AvailableIcons option="close" color="#ff000080" />
                </S.RemoveIcon>
              </S.Content>
            ))}
      </S.Values>

      <S.AddButton onClick={() => addValues()}>
        <Button
          variant="dashed"
          textColor={primaryColor}
          borderColor={primaryColor}
        >
          {buttonLabel}
        </Button>
      </S.AddButton>
    </S.Container>
  );
};

export default AddProjectImpact;
