/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from "./styles";
import InputBase from "../InputBase";
import { Theme } from "../../../hooks";
import { TUnits } from "../../../types";

type InputNumberBaseProps = {
  id?: string;
  type: TUnits;
  value: string;
  width?: string;
  border?: boolean;
  placeholder?: string;
  onChange: (val: string) => void;
};

const InputNumberBase: React.FC<InputNumberBaseProps> = ({
  id,
  type,
  value,
  onChange,
  placeholder,
  border = true,
  width = "140px",
}) => {
  const { textColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.InputBox width={width} border={border} borderColor={tertiaryColor}>
        {type === "R$" && <S.Money>R$</S.Money>}

        <InputBase
          id={id}
          value={value}
          textAlign="start"
          textColor={textColor}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />

        {(type === "%" ||
          type === "L" ||
          type === "mL" ||
          type === "Kg" ||
          type === "Km" ||
          type === "m²" ||
          type === "un." ||
          type === "KWh" ||
          type === "horas" ||
          type === "hectare" ||
          type === "Kg/hectare" ||
          type === "ton/hectare" ||
          type === "m³/hectare") && <S.EndUnit>{type}</S.EndUnit>}
      </S.InputBox>
    </S.Container>
  );
};

export default InputNumberBase;
