import axios, { AxiosError } from "axios";

import { IUpdateUser, IUser } from "../../types";
import { IManagerPref } from "../../types/manager";
import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_CLIENT_MANAGER_API_URL } = process.env;

const getManagerPreferences = async (entity: string): Promise<IManagerPref> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CLIENT_MANAGER_API_URL}/client-pref/${entity}`
    );

    return data.preferences;
  } catch (error) {
    throw new Error("Houve um erro ao obter as preferência da plataforma");
  }
};

const updateClient = async (
  clientData: IUpdateUser,
  token: string
): Promise<IUser> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CLIENT_MANAGER_API_URL}/client`,
      clientData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error(
      "Houve um erro ao processar sua edição de perfil, por favor tente novamente ou entre em contato"
    );
  }
};

const sendEmailToResetPassword = async (user: string, entity: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CLIENT_MANAGER_API_URL}/reset-password`,
      { user, entity }
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on reset password");
  }
};

const resetPassword = async (token: string, password: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CLIENT_MANAGER_API_URL}/reset-password/${token}`,
      { password }
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on reset password");
  }
};

export default {
  updateClient,
  resetPassword,
  getManagerPreferences,
  sendEmailToResetPassword,
};
