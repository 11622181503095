import * as S from "./styles";
import { IUser } from "../../../types";
import { Theme } from "../../../hooks";
import H4 from "../../atoms/Typography/H4";
import H5 from "../../atoms/Typography/H5";

export type TColors =
  | "textColor"
  | "primaryColor"
  | "tertiaryColor"
  | "secondaryColor"
  | "backgroundColor";

interface IColorsProps {
  label: string;
  colorSelected?: TColors;
  colors: { [key in TColors]: string };
  onClickColor: (color: TColors) => void;
  onChangeColor: (key: keyof IUser, color: string) => void;
}

const Colors: React.FC<IColorsProps> = ({
  label,
  colors,
  onClickColor,
  onChangeColor,
  colorSelected,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Container>
      <H4 color={textColor} fontWeight="bold">
        {label}
      </H4>

      <S.Options marginBottom={!!colorSelected}>
        <S.Option onClick={() => onClickColor("textColor")}>
          <H5 color={textColor}>Textos</H5>

          <S.Color color={colors.textColor} border={colors.textColor} />

          {colorSelected && colorSelected === "textColor" && (
            <S.ColorPicker
              color={colors[colorSelected]}
              show={colorSelected === "textColor"}
              onChange={(val) => onChangeColor(colorSelected, val.hex)}
              onChangeComplete={(val) => onChangeColor(colorSelected, val.hex)}
            />
          )}
        </S.Option>

        <S.Option onClick={() => onClickColor("backgroundColor")}>
          <H5 color={textColor}>Cor de fundo</H5>

          <S.Color color={colors.backgroundColor} border={textColor} />

          {colorSelected && colorSelected === "backgroundColor" && (
            <S.ColorPicker
              color={colors[colorSelected]}
              show={colorSelected === "backgroundColor"}
              onChange={(val) => onChangeColor(colorSelected, val.hex)}
              onChangeComplete={(val) => onChangeColor(colorSelected, val.hex)}
            />
          )}
        </S.Option>

        <S.Option onClick={() => onClickColor("primaryColor")}>
          <H5 color={textColor}>Cor primária</H5>

          <S.Color color={colors.primaryColor} border={textColor} />

          {colorSelected && colorSelected === "primaryColor" && (
            <S.ColorPicker
              color={colors[colorSelected]}
              show={colorSelected === "primaryColor"}
              onChange={(val) => onChangeColor(colorSelected, val.hex)}
              onChangeComplete={(val) => onChangeColor(colorSelected, val.hex)}
            />
          )}
        </S.Option>

        <S.Option onClick={() => onClickColor("secondaryColor")}>
          <H5 color={textColor}>Cor secundária</H5>

          <S.Color color={colors.secondaryColor} border={textColor} />

          {colorSelected && colorSelected === "secondaryColor" && (
            <S.ColorPicker
              color={colors[colorSelected]}
              show={colorSelected === "secondaryColor"}
              onChange={(val) => onChangeColor(colorSelected, val.hex)}
              onChangeComplete={(val) => onChangeColor(colorSelected, val.hex)}
            />
          )}
        </S.Option>

        <S.Option onClick={() => onClickColor("tertiaryColor")}>
          <H5 color={textColor}>Cor terciária</H5>

          <S.Color color={colors.tertiaryColor} border={textColor} />

          {colorSelected && colorSelected === "tertiaryColor" && (
            <S.ColorPicker
              color={colors[colorSelected]}
              show={colorSelected === "tertiaryColor"}
              onChange={(val) => onChangeColor(colorSelected, val.hex)}
              onChangeComplete={(val) => onChangeColor(colorSelected, val.hex)}
            />
          )}
        </S.Option>
      </S.Options>
    </S.Container>
  );
};

export default Colors;
