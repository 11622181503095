import styled from "styled-components";
import Button from "../../components/atoms/Button";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  gap: 24px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

export const ClientData = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const SendButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
  max-width: 500px;

  @media (max-width: 1160px) {
    align-self: center;
  }
`;
