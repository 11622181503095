import { PropsWithChildren, useState } from "react";

import * as S from "./styles";
import Menu from "../Menu";
import { Theme } from "../../../hooks";
import MobileMenu from "../MobileMenu";
import LogoutModal from "../LogoutModal";
import MobileHeader from "../MobileHeader";
import { TSections } from "../../../types";

interface IProps {
  bgColor?: string;
  pageIndex: number;
  mobileSection: TSections;
}

const Page: React.FC<PropsWithChildren<IProps>> = ({
  bgColor,
  children,
  pageIndex,
  mobileSection,
}) => {
  const [logout, setLogout] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(pageIndex);
  const [selectedMobileOption, setSelectedMobileOption] =
    useState<TSections>(mobileSection);

  const { backgroundColor } = Theme.useTheme();

  return (
    <>
      <S.Page backgroundColor={bgColor || backgroundColor}>
        <Menu
          selectedIndex={selectedOption}
          setLogout={(val) => setLogout(val)}
          setSelected={(val) => setSelectedOption(val)}
        />

        <MobileHeader setLogout={(val) => setLogout(val)} />

        <S.Container>{children}</S.Container>

        <MobileMenu
          selected={selectedMobileOption}
          setSelected={(val) => setSelectedMobileOption(val)}
        />
      </S.Page>

      {logout && (
        <LogoutModal isOpen={logout} onClose={() => setLogout(false)} />
      )}
    </>
  );
};

export default Page;
