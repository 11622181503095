import { TBrand } from "../types";
import { ICardsData, TBillingStatus, TRecurrence } from "../types/order";

export const emptyCardInfo: ICardsData = {
  creditCard: {
    ccv: "",
    number: "",
    holderName: "",
    expiryYear: "",
    expiryMonth: "",
  },
  creditCardHolderInfo: {
    name: "",
    email: "",
    phone: "",
    cpfCnpj: "",
    address: "",
    postalCode: "",
    mobilePhone: "",
    addressNumber: "",
  },
};

const brands: { [key in TBrand]: RegExp } = {
  elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
  hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  dinersClub: /^3(0[0-5]|[68]\d)\d{11}$/,
  americanExpress: /^3[47]\d{13}$/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  mastercard: /^(5[1-5]\d{4}|677189)\d{10}$/,
  visa: /^4\d{12}(\d{3})?$/,
};

export const creditCardBrand = (cardNumber: string): TBrand | undefined => {
  const brandsEntries = Object.entries(brands);

  const brandName = brandsEntries.filter((item) => item[1].test(cardNumber));

  if (!brandName[0]) return undefined;

  return brandName[0][0] as TBrand;
};

// const cards: string[] = [
//   "378282246310005", // American Express
//   "371449635398431", // American Express
//   "30569309025904", // Diners Club
//   "38520000023237", // Diners Club
//   "6011111111111117", // Discover
//   "6362970000457013", // Elo
//   "5263801239540687", // MasterCard
//   "6062825624254001", // Hipercard
//   "5555555555554444", // MasterCard
//   "5105105105105100", // MasterCard
//   "4111111111111111", // Visa
//   "4012888888881881", // Visa
// ];

export const maskCreditCard = (cardNumber: string): string => {
  const firstPart = cardNumber.substring(0, 4);
  const secondPart = cardNumber.substring(4, 8);
  const thirdPart = cardNumber.substring(8, 12);
  const forthPart = cardNumber.substring(12);

  if (!secondPart) return firstPart;

  if (!thirdPart) return `${firstPart} ${secondPart}`;

  if (!forthPart) return `${firstPart} ${secondPart} ${thirdPart}`;

  return `${firstPart} ${secondPart} ${thirdPart} ${forthPart}`;
};

export const billingStatus = (status: TBillingStatus): string => {
  if (status === "PENDING") return "Pendente";

  if (
    status === "RECEIVED" ||
    status === "CONFIRMED" ||
    status === "RECEIVED_IN_CASH"
  )
    return "Confirmado";

  if (status === "OVERDUE") return "Vencida";

  if (status === "REFUNDED") return "Estornada";

  if (status === "AWAITING_RISK_ANALYSIS") return "Em análise";

  if (status === "REFUND_REQUESTED") return "Estorno solicitado";

  if (status === "REFUND_IN_PROGRESS") return "Estorno em processamento";

  if (status === "CHARGEBACK_REQUESTED") return "Recebido chargeback";

  if (status === "CHARGEBACK_DISPUTE") return "Em disputa de chargeback";

  if (status === "DUNNING_REQUESTED") return "Em processo de negativação";

  if (status === "DUNNING_RECEIVED") return "Recuperada";

  return "-";
};

export const recurrence = (status: TRecurrence): string => {
  if (status === "signature") return "Assinatura";

  return "Avulsa";
};
