import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";

import * as S from "./styles";
import {
  defaultPrimaryColor,
  defaultTertiaryColor,
} from "../../constants/colors";
import { Manager } from "../../services";
import Button from "../../components/atoms/Button";
import Title from "../../components/atoms/Typography/Title";
import InputText from "../../components/molecules/InputText";

import ibioma from "../../components/assets/ibioma_logo.png";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState("");

  const { user } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, backgroundColor, tertiaryColor } =
    Theme.useTheme();

  const onResetHandler = async () => {
    if (!user) return;

    showLoading();
    try {
      const { email } = await Manager.sendEmailToResetPassword(
        userData,
        user.entity
      );

      setMessage(`E-mail enviado para ${email}!\nCheque sua caixa de entrada.`);
      newSuccess("E-mail enviado com sucesso!");
    } catch (error) {
      if (error && (error as { message: string }).message === "CREA001") {
        setMessage("Usuário/e-mail não encontrado");
        newError("Usuário não encontrado");
      } else {
        newError("Houve um erro ao redefinir a senha");
      }
    } finally {
      hideLoading();
    }
  };

  return (
    <S.Container color={tertiaryColor}>
      <S.Header>
        <S.Logo logo={ibioma} />

        <S.Login>
          <Button
            variant="solid"
            fontWeight="bold"
            onClick={() => navigate("/login")}
            textColor={backgroundColor || "#fafafa"}
            backgroundColor={primaryColor || defaultPrimaryColor}
          >
            Login
          </Button>
        </S.Login>
      </S.Header>

      <S.Content>
        <Title color={primaryColor}>Esqueceu sua senha?</Title>

        <S.ForgotForm>
          <InputText
            allLowerCase
            value={userData}
            labelWeight="bold"
            label="E-mail ou username"
            onChange={(val) => setUserData(val)}
            labelColor={textColor || "#000000"}
            placeholder="Digite seu e-mail ou username"
            backgroundColor={tertiaryColor || defaultTertiaryColor}
          />
        </S.ForgotForm>

        <Button
          variant="solid"
          fontWeight="bold"
          disabled={!userData}
          onClick={() => onResetHandler()}
          textColor={backgroundColor || "#fafafa"}
          backgroundColor={primaryColor || defaultPrimaryColor}
        >
          Solicitar redifinição de senha
        </Button>

        <S.Message color={primaryColor}>{message}</S.Message>
      </S.Content>

      <S.RegisterFormFooter>
        <S.LinkText color={textColor}>
          Ainda não possui uma conta?
          <Link to="/register"> Clique aqui para criar gratuitamente.</Link>
        </S.LinkText>
      </S.RegisterFormFooter>
    </S.Container>
  );
};

export default ForgotPassword;
