import { TBilling, TBillingOption, TFilterType } from "../types/order";

export const paymentMethod: { [key in TBilling]: TBillingOption } = {
  PIX: "Pix",
  BOLETO: "Boleto",
  CREDIT_CARD: "Cartão de crédito",
};

export const paymentMethodValues = Object.values(paymentMethod);

export const filterTypeObj: { [key in TFilterType]: string } = {
  period: "Por período",
  client: "Por cliente",
};
