import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import {
  TODS,
  IProject,
  TStorage,
  TOffsetIssuer,
  IImpactTopics,
} from "../../../../types/projects";
import Button from "../../../../components/atoms/Button";
import Page from "../../../../components/molecules/Page";
import { emptyProject } from "../../../../utils/projects";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H3 from "../../../../components/atoms/Typography/H3";
import H4 from "../../../../components/atoms/Typography/H4";
import { normalizeNumber } from "../../../../utils/numbers";
import { Project as ProjectService } from "../../../../services";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import InputText from "../../../../components/molecules/InputText";
import AddImages from "../../../../components/organisms/AddImages";
import ODSSelector from "../../../../components/organisms/ODSSelector";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import DropdownMenu from "../../../../components/organisms/DropdownMenu";
import ExcludeModal from "../../../../components/molecules/ExcludeModal";
import InputNumberBase from "../../../../components/atoms/InputNumberBase";
import { offsetIssuer, storageOptions } from "../../../../constants/projects";
import AddProjectImpact from "../../../../components/organisms/AddProjectImpact";
import AddProjectDescription from "../../../../components/organisms/AddProjectDescription";
import DiscardProjectsChangesModal from "../../../../components/molecules/DiscardProjectChangesModal";

const validateProject = (data: IProject) => {
  const errors: { [key: string]: string[] } = {};

  if (!data.name || data.name.length === 0)
    errors.name = [...(errors.name || []), "Nome do projeto é obrigatório"];

  if (!data.cardImage || data.cardImage.length === 0)
    errors.cardImage = [
      ...(errors.cardImage || []),
      "Imagem de fundo do card é obrigatório",
    ];

  if (!data.label || Object.keys(data.label).length === 0)
    errors.label = [...(errors.label || []), "Label para o card é obrigatório"];

  if (!data.shortDescription || Object.keys(data.shortDescription).length === 0)
    errors.shortDescription = [
      ...(errors.shortDescription || []),
      "Descrição curta para o card é obrigatório",
    ];

  if (data.shortDescription && Object.keys(data.shortDescription).length > 0) {
    Object.keys(data.shortDescription).map((item) => {
      if (
        !data.shortDescription[item] ||
        data.shortDescription[item].length === 0
      ) {
        errors[`shortDescription#${item}`] = [
          ...(errors[`shortDescription#${item}`] || []),
          "Descrição curta para o card é obrigatório",
        ];
      } else if (
        data.shortDescription[item].length > 0 &&
        data.shortDescription[item].replace(/[^a-zA-Z0-9]/g, "").length === 0
      ) {
        errors[`shortDescription#${item}`] = [
          ...(errors[`shortDescription#${item}`] || []),
          "Insira uma descrição válida",
        ];
      }
    });
  }

  if (data.description) {
    const indexes = data.description.reduce((acc: number[], curr) => {
      if (!curr.value || curr.value.length === 0) {
        return [...acc, curr.index];
      }

      return acc;
    }, []);

    if (indexes && indexes.length > 0) {
      indexes.map((item) => {
        errors[`description#${item}`] = [
          ...(errors[`description#${item}`] || []),
          "Descrição é obrigatório",
        ];
      });
    }
  }

  if (!data.impactSummary.summary || data.impactSummary.summary.length === 0)
    errors.summary = [
      ...(errors.summary || []),
      "Resumo dos impactos é obrigatório",
    ];

  if (!data.ods || data.ods.length === 0)
    errors.ods = [...(errors.ods || []), "ODS é obrigatório"];

  if (data.impactSummary.topics) {
    data.impactSummary.topics.map((item) => {
      if (!item.value || item.value.length === 0) {
        errors[`value#${item.index}`] = [
          ...(errors[`value#${item.index}`] || []),
          "Descrição do impacto é obrigatório",
        ];
      }

      if (!item.title || item.title.length === 0) {
        errors[`title#${item.index}`] = [
          ...(errors[`title#${item.index}`] || []),
          "Título do impacto é obrigatório",
        ];
      }
    });
  }

  return errors;
};

const NewProject: React.FC = () => {
  const triggerButton = useRef<HTMLDivElement>(null);

  const [isFixed, setIsFixed] = useState(false);
  const [back, setBack] = useState<boolean>(false);
  const [project, setProject] = useState<IProject>(emptyProject);
  const [excludeImpact, setExcludeImpact] = useState<number>(-1);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [changedSettings, setChangedSettings] = useState<boolean>(false);
  const [excludeDescription, setExcludeDescription] = useState<number>(-1);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition =
        triggerButton.current?.getBoundingClientRect().bottom || 0;

      setIsFixed(scrollPosition <= triggerPosition + 2000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(project) !== JSON.stringify(emptyProject)) {
      setChangedSettings(true);

      return;
    }

    setChangedSettings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const onBackHandler = () => {
    if (changedSettings && !back) {
      setBack(true);

      return;
    }

    if (back) {
      setBack(false);
    }

    navigate("/projetos");
  };

  const onChangeProjectCard = (
    key: "label" | "shortDescription",
    language: string,
    value: string
  ) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      if (key === "label") {
        return {
          ...curr,
          label: {
            ...curr.label,
            [language]: value,
          },
        };
      }

      return {
        ...curr,
        shortDescription: {
          ...curr.shortDescription,
          [language]: value,
        },
      };
    });
  };

  const onChangeProject = (key: keyof IProject, value: string) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      if (key === "creditQuantity") {
        return {
          ...curr,
          creditQuantity: +value.replace(/[^0-9]/g, ""),
        };
      }

      if (key === "ods") {
        if (curr.ods.includes(value as TODS))
          return {
            ...curr,
            ods: [...curr.ods.filter((item) => item !== value)],
          };

        return {
          ...curr,
          ods: [...curr.ods, value as TODS],
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onChangeProjectDetails = (
    key: keyof IProject["details"],
    value: string
  ) => {
    if (!project?.details) return;

    setProject((curr) => {
      if (!curr) return curr;

      if (key === "pricing") {
        return {
          ...curr,
          details: {
            ...curr.details,
            [key]: +(value || "").replace(/[^0-9]/g, ""),
          },
        };
      }

      if (key === "storage") {
        return {
          ...curr,
          details: {
            ...curr.details,
            [key]: storageOptions[value] as TStorage,
          },
        };
      }

      return {
        ...curr,
        details: {
          ...curr.details,
          [key]: value.toString() as TOffsetIssuer,
        },
      };
    });
  };

  const onChangeDescription = (index: number, value: string) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      const newDescription = project.description.map((item) => {
        if (item.index !== index) return item;

        return {
          ...item,
          value: value,
        };
      });

      return {
        ...curr,
        description: newDescription,
      };
    });
  };

  const onAddDescription = () => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      return {
        ...curr,
        description: [
          ...(curr?.description || []),
          { index: curr.description.length || 0, value: "" },
        ],
      };
    });
  };

  const onRemoveDescription = (index: number) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      const filteredDescription = curr.description.filter(
        (item) => item.index !== index
      );

      const normFilteredDescription = filteredDescription.map((item) => {
        if (item.index < index) return item;

        return {
          ...item,
          index: item.index - 1,
        };
      });

      return {
        ...curr,
        description: normFilteredDescription,
      };
    });

    setExcludeDescription(-1);
  };

  const onChangeSummary = (value: string) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      return {
        ...curr,
        impactSummary: {
          ...curr.impactSummary,
          summary: value,
        },
      };
    });
  };

  const onChangeImpacts = (
    index: number,
    key: keyof IImpactTopics,
    value: string
  ) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      const newTopics = project.impactSummary.topics.map((item) => {
        if (item.index !== index) return item;

        return {
          ...item,
          [key]: value,
        };
      });

      return {
        ...curr,
        impactSummary: {
          ...curr.impactSummary,
          topics: newTopics,
        },
      };
    });
  };

  const onAddImpact = () => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      return {
        ...curr,
        impactSummary: {
          ...curr?.impactSummary,
          topics: [
            ...(curr.impactSummary.topics || []),
            {
              value: "",
              image: "",
              title: "",
              index: curr.impactSummary.topics.length || 0,
            },
          ],
        },
      };
    });
  };

  const onRemoveImpact = (index: number) => {
    if (!project) return;

    setProject((curr) => {
      if (!curr) return curr;

      const filteredTopics = curr.impactSummary.topics.filter(
        (item) => item.index !== index
      );

      const normFilteredTopics = filteredTopics.map((item) => {
        if (item.index < index) return item;

        return {
          ...item,
          index: item.index - 1,
        };
      });

      return {
        ...curr,
        impactSummary: {
          ...curr?.impactSummary,
          topics: normFilteredTopics,
        },
      };
    });

    setExcludeImpact(-1);
  };

  const onSaveProject = async () => {
    try {
      showLoading();

      if (!project) return;

      const currErrors = validateProject(project);

      if (currErrors && Object.keys(currErrors).length > 0) {
        setErrors(currErrors);

        return;
      }

      setErrors({});

      const payload: IProject = {
        ...project,
        details: {
          ...project?.details,
          pricing: (project?.details.pricing || 0) / 100,
        },
      };

      await ProjectService.addProject(user.entity, payload, token);

      navigate("/projetos");
    } catch (error) {
      newError("Houve um erro ao salvar o projeto");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page pageIndex={0} mobileSection="projects">
      <S.Container>
        <S.Header>
          <Button
            size="small"
            variant="backButton"
            backgroundColor={tertiaryColor}
            onClick={() => onBackHandler()}
          >
            <AvailableIcons option="chevron" color={textColor} />
          </Button>
        </S.Header>

        <S.CardEdit>
          <H1 color={textColor} fontWeight="bold">
            Informações do card do projeto
          </H1>

          <AddImages
            size="cover"
            height="320px"
            showPreview={true}
            label="Imagem de fundo"
            errors={errors.cardImage}
            image={project?.cardImage || ""}
            onChange={(val) => onChangeProject("cardImage", val)}
          />

          <InputText
            errors={errors.label}
            label="Label (Português)"
            value={project?.label["pt"] || ""}
            onChange={(val) => onChangeProjectCard("label", "pt", val)}
          />

          <InputText
            label="Label (Inglês)"
            errors={errors.label}
            value={project?.label["en"] || ""}
            onChange={(val) => onChangeProjectCard("label", "en", val)}
          />

          <InputText
            limit={true}
            charLimit={200}
            label="Descrição curta (Português)"
            errors={errors["shortDescription#pt"]}
            value={project?.shortDescription["pt"] || ""}
            onChange={(val) =>
              onChangeProjectCard("shortDescription", "pt", val)
            }
          />

          <InputText
            limit={true}
            charLimit={200}
            label="Descrição curta (Inglês)"
            errors={errors["shortDescription#en"]}
            value={project?.shortDescription["en"] || ""}
            onChange={(val) =>
              onChangeProjectCard("shortDescription", "en", val)
            }
          />
        </S.CardEdit>

        <S.ProjectEdit borderColor={tertiaryColor}>
          <H1 color={textColor} fontWeight="bold">
            Informações da página do projeto
          </H1>

          <S.ProjectName>
            <InputText
              errors={errors.name}
              label="Nome do projeto"
              value={project?.name || ""}
              onChange={(val) => onChangeProject("name", val)}
            />
          </S.ProjectName>

          <S.Details>
            <S.Box key="pricing">
              <H4 color={textColor}>Preço</H4>

              <S.Detail backgroundColor={tertiaryColor}>
                <S.DetailIcon>
                  <AvailableIcons option="money" color={textColor} />
                </S.DetailIcon>

                <InputNumberBase
                  type="R$"
                  value={normalizeNumber(project?.details.pricing || 0, "R$")}
                  onChange={(val) => onChangeProjectDetails("pricing", val)}
                />
              </S.Detail>
            </S.Box>

            <S.Box key="credits">
              <H4 color={textColor}>Créditos disponíveis</H4>

              <S.Detail backgroundColor={tertiaryColor} width="200px">
                <S.DetailIcon>
                  <AvailableIcons option="seal" color={textColor} />
                </S.DetailIcon>

                <InputNumberBase
                  type="number"
                  value={normalizeNumber(
                    project?.creditQuantity || 0,
                    "number"
                  )}
                  onChange={(val) => onChangeProject("creditQuantity", val)}
                />
              </S.Detail>
            </S.Box>

            <S.Box key="storage">
              <H4 color={textColor}>Storage</H4>

              <S.Detail backgroundColor={tertiaryColor} width="280px">
                <S.DetailIcon>
                  <AvailableIcons option="storage" color={textColor} />
                </S.DetailIcon>

                <DropdownMenu
                  label=""
                  placeholder=""
                  labelWeight="400"
                  borderColor={tertiaryColor}
                  openBgColor={backgroundColor}
                  options={Object.keys(storageOptions)}
                  onSelect={(val) => onChangeProjectDetails("storage", val)}
                  selected={
                    `${project?.details.storage} storage` ||
                    Object.keys(storageOptions)[0]
                  }
                />
              </S.Detail>
            </S.Box>

            <S.Box key="offsetIssuer">
              <H4 color={textColor}>Certificadora</H4>

              <S.Detail backgroundColor={tertiaryColor} width="300px">
                <S.DetailIcon>
                  <AvailableIcons option="certified" color={textColor} />
                </S.DetailIcon>

                <DropdownMenu
                  label=""
                  placeholder=""
                  labelWeight="400"
                  options={offsetIssuer}
                  borderColor={tertiaryColor}
                  openBgColor={backgroundColor}
                  selected={project?.details.certifier || ""}
                  onSelect={(val) => onChangeProjectDetails("certifier", val)}
                />
              </S.Detail>
            </S.Box>
          </S.Details>

          <S.Description>
            <H3 color={textColor} fontWeight="bold">
              Descrição do projeto
            </H3>

            <AddProjectDescription
              errors={errors}
              buttonLabel="Adicionar parágrafo"
              addValues={() => onAddDescription()}
              values={project?.description}
              removeValues={(index) => setExcludeDescription(index)}
              changeValues={(index, val) => onChangeDescription(index, val)}
            />
          </S.Description>

          <S.Summary borderColor={tertiaryColor}>
            <H3 color={textColor} fontWeight="bold">
              Impactos do projeto
            </H3>

            <InputText
              limit={true}
              as="textarea"
              charLimit={1000}
              errors={errors.summary}
              label="Resumo dos impactos"
              onChange={(val) => onChangeSummary(val)}
              value={project?.impactSummary.summary || ""}
            />

            <AddProjectImpact
              errors={errors}
              addValues={() => onAddImpact()}
              buttonLabel="Adicionar impacto"
              values={project?.impactSummary.topics}
              removeValues={(index) => setExcludeImpact(index)}
              changeValues={(index, key, val) =>
                onChangeImpacts(index, key, val)
              }
            />
          </S.Summary>

          <S.ODS ref={triggerButton}>
            <H3 color={textColor} fontWeight="bold">
              Objetivos de Desenvolvimento Sustentável da ONU
            </H3>

            <P color={textColor}>
              Estes são os ODS da ONU que o projeto apoia.
            </P>

            <ODSSelector
              errors={errors.ods}
              onSelected={(val) => onChangeProject("ods", val)}
              selected={(project && project.ods) || ["1"]}
            />
          </S.ODS>
        </S.ProjectEdit>

        <S.SaveButton
          variant="solid"
          fontWeight="600"
          textColor={backgroundColor}
          disabled={!changedSettings}
          hasChanged={changedSettings}
          onClick={() => onSaveProject()}
          fixed={isFixed && changedSettings}
          backgroundColor={changedSettings ? primaryColor : `${primaryColor}40`}
        >
          Salvar alterações
        </S.SaveButton>

        {excludeDescription >= 0 && (
          <ExcludeModal
            isOpen={excludeDescription >= 0}
            onClose={() => setExcludeDescription(-1)}
            onExclude={() => onRemoveDescription(excludeDescription)}
          />
        )}

        {excludeImpact >= 0 && (
          <ExcludeModal
            isOpen={excludeImpact >= 0}
            onClose={() => setExcludeImpact(-1)}
            onExclude={() => onRemoveImpact(excludeImpact)}
          />
        )}

        {back && (
          <DiscardProjectsChangesModal
            isOpen={back}
            onClose={() => setBack(false)}
            onExclude={() => onBackHandler()}
          />
        )}
      </S.Container>
    </Page>
  );
};

export default NewProject;
