import styled, { css } from "styled-components";
import Button from "../../../../components/atoms/Button";

export const Container = styled.div`
  gap: 20px;
  display: flex;
  text-align: justify;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: fit-content;
  transform: rotate(180deg);
  justify-content: flex-start;
`;

export const CardEdit = styled.div`
  gap: 32px;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
`;

export const ProjectEdit = styled.div<BorderProps>`
  ${({ borderColor }) => css`
    gap: 32px;
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    border-top: 1px solid ${borderColor};
  `}
`;

export const ProjectName = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;

  h4,
  input {
    font-size: 1.8rem;
  }
`;

export const Details = styled.div`
  gap: 12px;
  display: flex;

  @media (max-width: 695px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 160px));
  }
`;

export const Box = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

type DetailProps = {
  width?: string;
  backgroundColor: string;
};

export const Detail = styled.div<DetailProps>`
  ${({ width, backgroundColor }) => css`
    gap: 4px;
    flex-grow: 1;
    display: flex;
    text-align: left;
    border-radius: 16px;
    align-items: center;
    padding: 4px 8px 4px 16px;
    width: ${width || "200px"};
    background-color: ${backgroundColor};

    ul,
    li {
      width: 100%;
    }
  `}
`;

export const DetailIcon = styled.div`
  width: 28px;
  height: 28px;
  flex-grow: 1;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Description = styled.div`
  gap: 16px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

type BorderProps = {
  borderColor: string;
};

export const Summary = styled.div<BorderProps>`
  ${({ borderColor }) => css`
    gap: 20px;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    border-bottom: 1px solid ${borderColor};
  `}
`;

export const ODS = styled.div`
  gap: 16px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

type ODSIMageProps = {
  ods: string;
  size: string;
};

export const ODSImage = styled.div<ODSIMageProps>`
  ${({ ods, size }) => css`
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url(${ods});
    background-size: ${size};
    background-position: center;
    background-repeat: no-repeat;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 600px) {
      justify-self: center;
    }
  `}
`;

type SaveButtonProps = {
  fixed: boolean;
  hasChanged: boolean;
};

export const SaveButton = styled(Button)<SaveButtonProps>`
  ${({ fixed, hasChanged }) => css`
    width: 100%;
    z-index: 1000;
    max-width: ${fixed ? "600px" : "100%"};
    align-self: center;
    justify-content: center;
    bottom: ${fixed && "100px"};
    display: ${hasChanged ? "flex" : "none"};
    position: ${fixed ? "fixed" : "relative"};
    box-shadow: ${fixed && "0 180px 100px 200px #00000040"};
  `}
`;
