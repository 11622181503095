import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onExclude: () => void;
}

const ExcludeProjectModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  onExclude,
}) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <H4 color="#000000">
          Deseja mesmo voltar excluir esse projeto? Se sim, ele não será mais
          exibido aos seus clientes
        </H4>

        <S.Buttons>
          <Button
            variant="solid"
            onClick={onClose}
            textColor={backgroundColor}
            backgroundColor={primaryColor}
          >
            Cancelar
          </Button>

          <Button
            variant="solid"
            textColor={backgroundColor}
            onClick={() => onExclude()}
            backgroundColor="#ff4040"
          >
            Excluir
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Content>
  );
};

export default ExcludeProjectModal;
