import { TODS, TOffsetIssuer, TStorage } from "../types/projects";

export const storageOptions: { [key: string]: TStorage } = {
  "Long-lived storage": "Long-lived",
  "Short-lived storage": "Short-lived",
};

export const offsetIssuer: TOffsetIssuer[] = [
  "Verra Carbon Standard",
  "UNFCCC",
  "Gold Standard",
];

export const ODS: TODS[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
];
