import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import { IMenuSections } from "../../../types";
import SeparatorLine from "../../atoms/SeparatorLine";
import AvailableIcons from "../../atoms/AvailableIcons";

const { REACT_APP_IBIOMA_LOGO } = process.env;

const sections: IMenuSections[] = [
  { icon: "globe", label: "Projetos", navigate: "projetos" },
  { icon: "statement", label: "Vendas", navigate: "vendas" },
  { icon: "", label: "", navigate: "" },
  { icon: "analytics", label: "Dashboard", navigate: "dashboard" },
  {
    icon: "certified",
    label: "Consolidador Certificados",
    navigate: "certificados",
  },
  { icon: "", label: "", navigate: "" },
  { icon: "person", label: "Meu perfil", navigate: "perfil" },
];

interface IProps {
  selectedIndex: number;
  setLogout: (val: boolean) => void;
  setSelected: (index: number) => void;
}

const Menu: React.FC<IProps> = ({ setLogout, setSelected, selectedIndex }) => {
  const navigate = useNavigate();

  const { textColor, tertiaryColor } = Theme.useTheme();

  const onClickHandler = (section: string, index: number) => {
    setSelected(index);

    navigate(`/${section}`);
  };

  return (
    <S.Container backgroundColor={tertiaryColor}>
      <S.Header>
        <S.Logo logo={REACT_APP_IBIOMA_LOGO || ""} />

        <Button
          variant="solid"
          textColor={textColor}
          backgroundColor="#18181810"
          onClick={() => setLogout(true)}
        >
          sair
        </Button>
      </S.Header>

      <S.Sections>
        {sections.map((section, index) => {
          if (section.icon === "") {
            return <SeparatorLine key={index} />;
          }
          return (
            <S.Section
              key={`${section.icon}#${index}`}
              isSelected={index === selectedIndex}
              onClick={() => onClickHandler(section.navigate, index)}
            >
              <S.Icon>
                <AvailableIcons option={section.icon} color={textColor} />
              </S.Icon>

              <H4 color={textColor}>{section.label}</H4>
            </S.Section>
          );
        })}
      </S.Sections>
    </S.Container>
  );
};

export default Menu;
