import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import * as S from "./styles";
import {
  defaultPrimaryColor,
  defaultTertiaryColor,
} from "../../../../constants/colors";
import { Manager } from "../../../../services";
import SuccessfulChange from "../SuccessfulChange";
import Button from "../../../../components/atoms/Button";
import H1 from "../../../../components/atoms/Typography/H1";
import { Loading, Snackbar, Theme } from "../../../../hooks";
import InputText from "../../../../components/molecules/InputText";

import ibioma from "../../../../components/assets/ibioma_logo.png";

const NewPassword: React.FC = () => {
  const [userData, setUserData] = useState<{
    logo: string;
    company: string;
    contactName: string;
  } | null>(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const navigate = useNavigate();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const resetToken = urlParams.get("reset_token");

  useEffect(() => {
    if (!resetToken) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetPasswordHandler = async () => {
    if (!password || !confirmPassword || !resetToken) return;

    if (password !== confirmPassword)
      return newError("As senhas não coincidem");

    showLoading();

    try {
      const { contactName, company, logo } = await Manager.resetPassword(
        resetToken,
        password
      );

      setUserData({ contactName, logo, company });
    } catch (error) {
      if (
        error &&
        ["LGOT003", "TOKE001"].includes((error as { message: string }).message)
      ) {
        newError("Expirou! Clique em esqueci minha senha e comece novamente");
      } else {
        newError("Houve um erro ao redefinir a senha");
      }
    } finally {
      hideLoading();
    }
  };

  if (userData)
    return (
      <SuccessfulChange
        company={userData.company}
        logo={userData.logo || ""}
        contactName={userData.contactName}
      />
    );

  return (
    <S.Container color={tertiaryColor}>
      <S.Header>
        <S.Logo logo={ibioma} />

        <S.Login>
          <Button
            variant="solid"
            fontWeight="bold"
            onClick={() => navigate("/login")}
            textColor={backgroundColor || "#fafafa"}
            backgroundColor={primaryColor || defaultPrimaryColor}
          >
            Login
          </Button>
        </S.Login>
      </S.Header>

      <S.Content>
        <H1 color={primaryColor}>Esqueceu sua senha? Redefina aqui!</H1>

        <S.ForgotForm>
          <InputText
            type="password"
            value={password}
            onChange={setPassword}
            label="Digite uma nova senha"
            placeholder="Digite sua nova senha"
            backgroundColor={tertiaryColor || defaultTertiaryColor}
          />

          <InputText
            type="password"
            label="Repetir senha"
            value={confirmPassword}
            onChange={setConfirmPassword}
            placeholder="Confirmar a nova senha"
            backgroundColor={tertiaryColor || defaultTertiaryColor}
          />

          <Button
            variant="solid"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={onResetPasswordHandler}
            disabled={!password || !(password === confirmPassword)}
          >
            Salvar nova senha
          </Button>
        </S.ForgotForm>
      </S.Content>

      <S.RegisterFormFooter>
        <S.LinkText color={textColor}>
          Ainda não possui uma conta?
          <Link to="/register"> Clique aqui para criar gratuitamente.</Link>
        </S.LinkText>
      </S.RegisterFormFooter>
    </S.Container>
  );
};

export default NewPassword;
