import * as S from "./styles";

import { Theme } from "../../hooks";
import Page from "../../components/molecules/Page";
import H3 from "../../components/atoms/Typography/H3";
import ConstructingIcon from "../../components/icons/ConstructingIcon";
import TitleDescription from "../../components/molecules/TitleDescription";

const Dashboard: React.FC = () => {
  const { textColor } = Theme.useTheme();

  return (
    <Page pageIndex={3} mobileSection="statement">
      <S.Content>
        <TitleDescription
          title="Dashboard"
          description="Com o dashboard você tem visibilidade de métricas relacionadas a suas compensações"
        />

        <S.Soon>
          <S.Icon>
            <ConstructingIcon />
          </S.Icon>

          <H3 color={textColor} fontWeight="bold">
            Em construção
          </H3>
        </S.Soon>
      </S.Content>
    </Page>
  );
};

export default Dashboard;
