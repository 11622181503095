import axios from "axios";
import { IClients } from "../../types/clients";

const { REACT_APP_CLIENT_API_URL } = process.env;

const getClients = async (token: string): Promise<IClients> => {
  try {
    const { data } = await axios.get(`${REACT_APP_CLIENT_API_URL}/clients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.clients;
  } catch (error) {
    throw new Error("Error on Clients.getClients");
  }
};

export default {
  getClients,
};
