import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import InputText from "../../molecules/InputText";
import { IDescription } from "../../../types/projects";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  buttonLabel: string;
  addValues: () => void;
  values: IDescription[] | undefined;
  errors?: { [key: string]: string[] };
  removeValues: (index: number) => void;
  changeValues: (index: number, value: string) => void;
}

const AddProjectDescription: React.FC<IProps> = ({
  values,
  errors,
  addValues,
  buttonLabel,
  changeValues,
  removeValues,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.Values>
        {values &&
          values.length > 0 &&
          values
            .sort((a, b) => a.index - b.index)
            .map((item) => (
              <S.Content key={item.index}>
                <S.Inputs key={`inputsaddprojectdescr#${item.index}`}>
                  <InputText
                    label=""
                    limit={true}
                    as="textarea"
                    charLimit={1000}
                    labelWeight="regular"
                    value={item.value || ""}
                    onChange={(val) => changeValues(item.index, val)}
                    errors={errors && errors[`description#${item.index}`]}
                  />
                </S.Inputs>

                <S.RemoveIcon
                  onClick={() => removeValues(item.index)}
                  key={`removeprojectdescr#${item.index}`}
                >
                  <AvailableIcons option="close" color="#ff000080" />
                </S.RemoveIcon>
              </S.Content>
            ))}
      </S.Values>

      <S.AddButton onClick={() => addValues()}>
        <Button
          variant="dashed"
          textColor={primaryColor}
          borderColor={primaryColor}
        >
          {buttonLabel}
        </Button>
      </S.AddButton>
    </S.Container>
  );
};

export default AddProjectDescription;
