import { useEffect, useState } from "react";

import * as S from "./styles";
import { IClients } from "../../types/clients";
import Page from "../../components/molecules/Page";
import { Certificate, Clients } from "../../services";
import Skeleton from "../../components/atoms/Skeleton";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import AddDocument from "../../components/organisms/AddDocument";
import DropdownMenu from "../../components/organisms/DropdownMenu";
import TitleDescription from "../../components/molecules/TitleDescription";

const Certificates: React.FC = () => {
  const [pdf, setPdf] = useState<string>();
  const [year, setYear] = useState<string>();
  const [client, setClient] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [pdfName, setPdfName] = useState<string>();
  const [clients, setClients] = useState<IClients>();
  const [filtered, setFiltered] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [clientsOpt, setClientsOpt] = useState<string[]>();

  const { token } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor, tertiaryColor, backgroundColor, primaryColor } =
    Theme.useTheme();

  useEffect(() => {
    const getClients = async () => {
      try {
        setLoading(true);
        const clientsData = await Clients.getClients(token);

        setClients(clientsData);
        setClientsOpt(Object.values(clientsData));
      } catch (error) {
        newError("Houve um erro ao obter os clientes");
      } finally {
        setLoading(false);
      }
    };

    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const anoAtual = new Date().getFullYear();

  const years: string[] = [];
  for (let ano = 2023; ano <= anoAtual; ano++) {
    years.push(ano.toString());
  }

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const onSendCertificate = async () => {
    try {
      if (!clients || Object.keys(clients).length === 0) return;

      showLoading();

      const clientIdIndex = Object.values(clients).indexOf(client || "");

      const clientId = Object.keys(clients)[clientIdIndex];

      await Certificate.sendCertificate(clientId || "", year || "", token, pdf);

      setPdf(undefined);
      setYear(undefined);
      setClient(undefined);
      setPdfName(undefined);

      newSuccess("Certificado carregado com sucesso!");
    } catch (error) {
      newError("Houve um erro ao enviar o certificado. Tente novamente");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page pageIndex={4} mobileSection="certified">
      <S.Content>
        <TitleDescription
          title="Consolidador de certificados"
          description="Aqui você poderá consolidar os certificados de aposentadoria dos créditos, gerados pelas intituições detentoras dos padrões para certificação dos créditos de carbono, como o Verra (Verified Carbon Standard)."
        />
      </S.Content>

      {loading ? (
        <Skeleton
          direction="row"
          numberSkeletons={3}
          skeletonWidth="360px"
          skeletonHeight="440px"
        />
      ) : (
        <S.Container>
          <S.ClientData>
            <DropdownMenu
              search={search}
              label="Cliente"
              useSearch={true}
              labelWeight="bold"
              options={clientsOpt}
              labelColor={textColor}
              bgColor={tertiaryColor}
              selected={client || ""}
              filteredOptions={filtered}
              openBgColor={backgroundColor}
              placeholder="Selecione o cliente"
              onSelect={(val) => setClient(val)}
              onClearSearch={() => setSearch(undefined)}
              onSearch={(val) => onSearch(val, clientsOpt || [""])}
            />

            <DropdownMenu
              label="Ano"
              options={years}
              search={search}
              useSearch={true}
              labelWeight="bold"
              selected={year || ""}
              labelColor={textColor}
              bgColor={tertiaryColor}
              filteredOptions={filtered}
              openBgColor={backgroundColor}
              placeholder="Selecione o ano"
              onSelect={(val) => setYear(val)}
              onSearch={(val) => onSearch(val, years)}
              onClearSearch={() => setSearch(undefined)}
            />
          </S.ClientData>

          <AddDocument
            documentName={pdfName}
            label="Adicione o certificado"
            onChange={(val) => setPdf(val)}
            setDocumentName={(val) => setPdfName(val)}
          />

          <S.SendButton
            variant="solid"
            fontWeight="600"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={() => onSendCertificate()}
            disabled={!client || !year || !pdf}
          >
            Enviar certificado
          </S.SendButton>
        </S.Container>
      )}
    </Page>
  );
};

export default Certificates;
