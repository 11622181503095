import styled, { css } from "styled-components";

import H4 from "../../atoms/Typography/H4";

export const PaymentDetails = styled.div`
  gap: 40px;
  top: 20vh;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  border-radius: 12px;
  justify-content: space-between;

  @media (max-width: 1480px) {
    flex-direction: column;
  }

  @media (max-width: 820px) {
    padding: 8px 0;
  }
`;

export const Summary = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1480px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const IconAndDetail = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

type IconAndTextProps = {
  backgroundColor: string;
};

export const IconAndText = styled.div<IconAndTextProps>`
  ${({ backgroundColor }) => css`
    gap: 8px;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: ${backgroundColor};

    @media (max-width: 580px) {
      padding: 12px 12px;

      h5 {
        text-align: center;
      }
    }
  `}
`;

export const Icon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 580px) {
    width: 20px;
    height: 20px;
  }
`;

export const PaymentMethod = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1480px) {
    align-items: center;
  }
`;

export const QRCode = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
`;

type InvoiceProps = {
  textColor: string;
  backgroundColor: string;
};

export const Invoice = styled.a<InvoiceProps>`
  ${({ textColor, backgroundColor }) => css`
    gap: 12px;
    width: 320px;
    display: flex;
    cursor: pointer;
    font-size: 1.4rem;
    align-self: center;
    padding: 12px 36px;
    text-align: center;
    border-radius: 20px;
    align-items: center;
    color: ${textColor};
    text-decoration: none;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: 28px;
      height: 28px;
    }
  `}
`;

export const CopyCode = styled.div`
  width: 560px;
  display: flex;
  align-items: center;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Statement = styled(H4)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Card = styled.div`
  width: 400px;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`;
