import { ChromePicker } from "react-color";
import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 20px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

type OptionsProps = {
  marginBottom: boolean;
};

export const Options = styled.div<OptionsProps>`
  ${({ marginBottom }) => css`
    gap: 24px 12px;
    display: flex;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${marginBottom && "100px"};

    @media (max-width: 1540px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, 140px);
    }

    @media (max-width: 1240px) {
      margin-bottom: 0;
    }

    @media (max-width: 412px) {
      justify-content: center;
    }
  `}
`;

export const Option = styled.div`
  gap: 8px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

type ColorProps = {
  color: string;
  border: string;
};

export const Color = styled.div<ColorProps>`
  ${({ color, border }) => css`
    width: 60px;
    height: 60px;
    padding: 2px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid ${border};
    background-color: ${color};
  `}
`;

type ColorPickerProps = {
  show: boolean;
};

export const ColorPicker = styled(ChromePicker)<ColorPickerProps>`
  ${({ show }) => css`
    top: 100%;
    left: -20px;
    z-index: 1000;
    position: absolute;
    align-self: center;
    display: ${!show && "none"};

    @media (max-width: 770px) {
      left: -40px;
    }
  `}
`;
