/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import {
  defaultPrimaryColor,
  defaultTertiaryColor,
} from "../../constants/colors";
import { Auth, Theme } from "../../hooks";
import Button from "../../components/atoms/Button";
import Title from "../../components/atoms/Typography/Title";
import Small from "../../components/atoms/Typography/Small";
import InputText from "../../components/molecules/InputText";

import ibioma from "../../components/assets/ibioma_logo.png";

const RegisterFormSecondStep: React.FC = () => {
  const [formData, setFormData] = useState({
    user: "",
    password: "",
  });

  const navigate = useNavigate();
  const { login } = Auth.useAuth();
  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const onLogin = () => {
    login(formData.user, formData.password);
  };

  const isReady = formData.user && formData.password;

  return (
    <S.Information color={tertiaryColor}>
      <S.Header>
        <S.Logo logo={ibioma} />
      </S.Header>

      <S.Content>
        <Title color={primaryColor}>Faça seu login</Title>

        <S.LoginForm>
          <InputText
            label="Login"
            allLowerCase
            labelWeight="bold"
            value={formData.user}
            placeholder="E-mail ou username"
            labelColor={textColor || "#000000"}
            backgroundColor={tertiaryColor || defaultTertiaryColor}
            onChange={(val) =>
              setFormData((curr) => ({
                ...curr,
                user: val.toLowerCase().trim(),
              }))
            }
          />

          <InputText
            label="Senha"
            type="password"
            value={formData.password}
            placeholder="Digite sua senha"
            labelColor={textColor || "#000000"}
            backgroundColor={tertiaryColor || defaultTertiaryColor}
            onChange={(val) =>
              setFormData((curr) => ({ ...curr, password: val }))
            }
          />

          <S.ForgotPassword
            underlineColor={primaryColor}
            onClick={() => navigate("/forgot-password")}
          >
            <Small color={primaryColor}>Esqueci minha senha</Small>
          </S.ForgotPassword>
        </S.LoginForm>

        <Button
          variant="solid"
          fontWeight="bold"
          disabled={!isReady}
          onClick={() => onLogin()}
          textColor={backgroundColor || "#fafafa"}
          backgroundColor={primaryColor || defaultPrimaryColor}
        >
          Entrar
        </Button>
      </S.Content>
    </S.Information>
  );
};

export default RegisterFormSecondStep;
