import { Navigate, Route, Routes } from "react-router-dom";

import Profile from "../../pages/Profile";
import Projects from "../../pages/Projects";
import Dashboard from "../../pages/Dashboard";
import Statements from "../../pages/Statements";
import Certificates from "../../pages/Certificates";
import MaterialsAndContent from "../../pages/Certificates";
import Project from "../../pages/Projects/components/Project";
import Statement from "../../pages/Statements/components/Statement";
import NewProject from "../../pages/Projects/components/NewProject";

const PrivateRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/perfil" element={<Profile />} />
        <Route path="/projetos" element={<Projects />} />
        <Route path="/vendas" element={<Statements />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/projetos/new" element={<NewProject />} />
        <Route path="/certificados" element={<Certificates />} />
        <Route path="/materiais" element={<MaterialsAndContent />} />

        <Route path="/projetos/:id" element={<Project />} />
        <Route path="/vendas/:id" element={<Statement />} />

        <Route path="*" element={<Navigate to="/projetos" />} />
      </Routes>
    </>
  );
};

export default PrivateRoutes;
