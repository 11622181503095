/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import { IUser } from "../../types";
import { Manager } from "../../services";
import Page from "../../components/molecules/Page";
import { maskCPFOrCNPJ } from "../../utils/numbers";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import InputText from "../../components/molecules/InputText";
import AddImages from "../../components/organisms/AddImages";
import SeparatorLine from "../../components/atoms/SeparatorLine";
import Colors, { TColors } from "../../components/molecules/Colors";
import TitleDescription from "../../components/molecules/TitleDescription";

const validateProfile = (data: IUser): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.mail || data.mail.length === 0)
    errors.mail = [...(errors.mail || []), "E-mail é obrigatório"];

  if (
    data.mail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.mail)
  )
    errors.mail = [...(errors.mail || []), "Verifique o e-mail"];

  if (!data.cnpj) errors.cnpj = [...(errors.cnpj || []), "CNPJ obrigatório"];

  if (data.cnpj && data.cnpj.length !== 14)
    errors.cnpj = [...(errors.cnpj || []), "CNPJ incompleto"];

  return errors;
};

const Profile: React.FC = () => {
  const { user, setUserHandler, token } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor, primaryColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const triggerButton = useRef<HTMLDivElement>(null);

  const [isFixed, setIsFixed] = useState(false);
  const [settings, setSettings] = useState<IUser>(user);
  const [selectedColor, setSelectedColor] = useState<TColors>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [changedSettings, setChangedSettings] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition =
        triggerButton.current?.getBoundingClientRect().bottom || 0;

      setIsFixed(scrollPosition <= triggerPosition - 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(user) !== JSON.stringify(settings)) {
      setChangedSettings(true);

      return;
    }

    setChangedSettings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const onSaveChanges = async () => {
    try {
      showLoading();

      if (changedSettings) {
        const currErrors = validateProfile(settings);

        if (currErrors && Object.keys(currErrors).length > 0) {
          setErrors(currErrors);

          return;
        }

        const payload = {
          logo: settings.logo,
          textColor: settings.textColor,
          primaryColor: settings.primaryColor,
          tertiaryColor: settings.tertiaryColor,
          secondaryColor: settings.secondaryColor,
          backgroundColor: settings.backgroundColor,
          mail: settings.mail?.toLowerCase().trim(),
        };

        const newSettings = await Manager.updateClient(payload, token);

        setUserHandler(newSettings);
        setSettings(newSettings);
        setChangedSettings(false);
        setIsFixed(false);

        newSuccess("Dados atualizados com sucesso!");
      }
    } catch (error: any) {
      newError(error.toString());
      setSettings(user);
    } finally {
      hideLoading();
    }
  };

  const onChangeHandler = (key: keyof IUser, value: any) => {
    setSettings((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  return (
    <Page pageIndex={6} mobileSection="profile">
      <S.Content>
        <TitleDescription
          title="Perfil"
          description="Ajuste suas definções de conta aqui. As configurações de cores e logo impactarão a plataforma de seus clientes, bem como a página de sustentabilidade (Report) de cada um. Para edição dos campos desabilitados, por serem campos sensíveis, entre em contato com o time Ibioma."
        />

        {selectedColor && (
          <S.Backdrop onClick={() => setSelectedColor(undefined)} />
        )}

        <S.Profile>
          <S.TextAndColors>
            <S.TextInputs>
              <InputText
                key="company"
                disabled={true}
                onChange={() => null}
                labelColor={textColor}
                label="Nome da empresa"
                backgroundColor={tertiaryColor}
                value={settings.companyName || ""}
              />

              <InputText
                key="username"
                disabled={true}
                onChange={() => null}
                labelColor={textColor}
                label="Username/Entidade"
                value={settings.entity || ""}
                backgroundColor={tertiaryColor}
              />

              <InputText
                key="mail"
                label="E-mail"
                errors={errors.mail}
                labelColor={textColor}
                value={settings.mail || ""}
                backgroundColor={backgroundColor}
                onChange={(val) => onChangeHandler("mail", val)}
              />

              <InputText
                label="CNPJ"
                charLimit={18}
                disabled={true}
                onChange={() => null}
                labelColor={textColor}
                backgroundColor={tertiaryColor}
                value={maskCPFOrCNPJ(settings.cnpj || "")}
              />
            </S.TextInputs>

            <SeparatorLine opacity={0.2} />

            <S.Colors>
              <Colors
                label="Cores"
                colorSelected={selectedColor}
                colors={{
                  textColor: settings.textColor,
                  primaryColor: settings.primaryColor,
                  tertiaryColor: settings.tertiaryColor,
                  secondaryColor: settings.secondaryColor,
                  backgroundColor: settings.backgroundColor,
                }}
                onClickColor={(color) => setSelectedColor(color)}
                onChangeColor={(key, color) => onChangeHandler(key, color)}
              />
            </S.Colors>
          </S.TextAndColors>

          <S.LogoBox ref={triggerButton}>
            <AddImages
              label="Logo"
              showPreview={true}
              flexDirection="column"
              image={settings.logo || ""}
              onChange={(val) => onChangeHandler("logo", val)}
            />
          </S.LogoBox>
        </S.Profile>

        <S.SaveButton
          variant="solid"
          fontWeight="600"
          textColor={backgroundColor}
          disabled={!changedSettings}
          hasChanged={changedSettings}
          onClick={() => onSaveChanges()}
          fixed={isFixed && changedSettings}
          backgroundColor={changedSettings ? primaryColor : `${primaryColor}40`}
        >
          Salvar alterações
        </S.SaveButton>
      </S.Content>
    </Page>
  );
};

export default Profile;
