import { IProject } from "../types/projects";

export const emptyProject: IProject = {
  name: "",
  ods: ["1"],
  cardImage: "",
  creditQuantity: 0,
  label: { en: "", pt: "" },
  shortDescription: { en: "", pt: "" },
  description: [{ index: 0, value: "" }],
  details: {
    pricing: 0,
    storage: "Short-lived",
    certifier: "Verra Carbon Standard",
  },
  impactSummary: {
    summary: "",
    topics: [{ index: 0, title: "", value: "", image: "" }],
  },
};
