import React, { useRef } from "react";

import * as S from "./styles";
import P from "../../atoms/Typography/P";
import H4 from "../../atoms/Typography/H4";
import { Snackbar, Theme } from "../../../hooks";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  label?: string;
  errors?: string[];
  documentName?: string;
  onChange: (data: string) => void;
  setDocumentName?: (name: string) => void;
}

const AddDocument: React.FC<IProps> = ({
  label,
  errors,
  onChange,
  documentName,
  setDocumentName,
}) => {
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  const input = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          onChange(base64);
          setDocumentName && setDocumentName(file.name);
        }

        if (input.current) input.current.value = "";
      };
    } else {
      newError("Houve um erro ao carregar o documento");
    }
  };

  return (
    <S.Container>
      {label && (
        <H4 color={textColor} fontWeight="bold">
          {label}
        </H4>
      )}

      <S.DocumentBox>
        <S.ButtonUploadImage
          borderColor={textColor}
          backgroundColor={tertiaryColor}
        >
          <S.PlusIcon borderColor={textColor}>
            <AvailableIcons option="plus" />
          </S.PlusIcon>
          Adicionar Documento (.pdf)
          <input
            type="file"
            ref={input}
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </S.ButtonUploadImage>

        {documentName && (
          <S.UploadedDocument>
            <S.Icon>
              <AvailableIcons option="document" />
            </S.Icon>

            <P color={textColor}>{documentName}</P>
          </S.UploadedDocument>
        )}
      </S.DocumentBox>

      {errors && errors.length ? (
        <S.Error color="#FF4D4F">Erro: {errors.join(", ")}</S.Error>
      ) : null}
    </S.Container>
  );
};

export default AddDocument;
