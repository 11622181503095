import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Project } from "../../services";
import { IProject } from "../../types/projects";
import Page from "../../components/molecules/Page";
import { Auth, Snackbar, Theme } from "../../hooks";
import H2 from "../../components/atoms/Typography/H2";
import H4 from "../../components/atoms/Typography/H4";
import H5 from "../../components/atoms/Typography/H5";
import Skeleton from "../../components/atoms/Skeleton";
import MoneyIcon from "../../components/icons/MoneyIcon";
import StorageIcon from "../../components/icons/StorageIcon";
import CertifiedIcon from "../../components/icons/CertifiedIcon";
import TitleDescription from "../../components/molecules/TitleDescription";

const Projects: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<IProject[]>();

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { primaryColor, backgroundColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      try {
        const projectsData = await Project.getProjects(user.entity, token);

        setProjects(projectsData);
      } catch (error) {
        newError(
          "Houve um erro ao obter os dados dos projetos disponíveis. Recarregue a página, por gentileza, para tentar novamente"
        );
      } finally {
        setLoading(false);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page pageIndex={0} mobileSection="projects">
      <S.Content>
        <TitleDescription
          title="Projetos"
          description="Aqui você pode acrescentar ou editar os projetos que disponibilizará aos seus clientes."
        />

        <S.AddProject
          variant="dashed"
          textColor={primaryColor}
          borderColor={primaryColor}
          onClick={() => navigate("/projetos/new")}
        >
          Adicionar projeto
        </S.AddProject>

        <S.Projects>
          {loading && (
            <Skeleton
              direction="row"
              numberSkeletons={3}
              skeletonWidth="360px"
              skeletonHeight="440px"
            />
          )}

          {!loading &&
            projects &&
            projects.length > 0 &&
            projects.map((project) => (
              <S.ProjectCard
                key={project.id}
                backgroundImage={project.cardImage}
                onClick={() => navigate(`/projetos/${project.id}`)}
              >
                <H2 color={backgroundColor} fontWeight="bold">
                  {project.label["pt"]}
                </H2>

                <S.Details>
                  <S.IconAndText>
                    <S.Icon>
                      <MoneyIcon color={backgroundColor} />
                    </S.Icon>

                    <H5 color={backgroundColor}>
                      R$ {project.details.pricing.toFixed(2).replace(".", ",")}
                    </H5>
                  </S.IconAndText>

                  <S.IconAndText>
                    <S.Icon>
                      <CertifiedIcon color={backgroundColor} />
                    </S.Icon>

                    <H5 color={backgroundColor}>{project.details.certifier}</H5>
                  </S.IconAndText>

                  <S.IconAndText>
                    <S.Icon>
                      <StorageIcon color={backgroundColor} />
                    </S.Icon>

                    <H5 color={backgroundColor}>
                      {project.details.storage} carbon storage
                    </H5>
                  </S.IconAndText>
                </S.Details>

                <H4 color={backgroundColor}>
                  {project.shortDescription["pt"]}
                </H4>
              </S.ProjectCard>
            ))}
        </S.Projects>
      </S.Content>
    </Page>
  );
};

export default Projects;
