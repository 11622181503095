/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from "react";

import {
  LOCAL_STORAGE_USER_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
} from "../../constants";
import { Auth } from "../../services";
import { Loading, Snackbar } from "..";
import { IAuthContext, IUser } from "../../types";

const emptyContext: IAuthContext = {
  user: {
    id: "",
    mail: "",
    cnpj: "",
    logo: "",
    phone: "",
    textColor: "",
    companyName: "",
    entity: "ibioma",
    primaryColor: "",
    tertiaryColor: "",
    secondaryColor: "",
    backgroundColor: "",
  },
  token: "",
  isLogged: false,
  login: () => {},
  logout: () => {},
  setUserHandler: () => {},
  setTokenHandler: () => {},
};

const Context = createContext(emptyContext);
const useAuth = () => useContext(Context);

const initialUserState: IUser = {
  ...emptyContext.user,
  ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || "{}"),
};

const initialTokenState: string =
  localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || "";

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState(initialUserState);
  const [token, setToken] = useState(initialTokenState);

  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const setTokenHandler = (tokenValue: string) => {
    setToken(tokenValue);
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, tokenValue);
  };

  const setUserHandler = (userData: IUser) => {
    setUser((current) => {
      localStorage.setItem(
        LOCAL_STORAGE_USER_KEY,
        JSON.stringify({ ...current, ...userData })
      );

      return { ...current, ...userData };
    });
  };

  const login: IAuthContext["login"] = async (
    user,
    password,
    entity,
    oAuthProvider
  ) => {
    try {
      showLoading();
      let client;

      if (user && password && !oAuthProvider)
        client = await Auth.login({ user, password });

      if (client) {
        localStorage.removeItem("@searchParams");

        setUserHandler(client.user);
        setTokenHandler(client.token);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Houve um erro ao fazer o login, tente novamente";

      newError(errorMessage);
    } finally {
      hideLoading();
    }
  };

  const logout = () => {
    setUser(emptyContext.user);
    setToken("");

    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  };

  const providerValue = {
    user,
    token,
    login,
    logout,
    setUserHandler,
    setTokenHandler,
    isLogged: !!token,
  };

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export default {
  useAuth,
  Provider,
};
