import styled, { css } from "styled-components";

import Small from "../../atoms/Typography/Small";

export const Container = styled.div`
  display: flex;
  padding: 0 12px;
  flex-direction: column;

  @media (max-width: 420px) {
    padding: 0;
  }
`;

type BoxImagesProps = {
  flexDirection: string;
};

export const BoxImages = styled.div<BoxImagesProps>`
  ${({ flexDirection }) => css`
    gap: 40px;
    display: flex;
    flex-direction: ${flexDirection};

    @media (max-width: 1240px) {
      flex-direction: row;
    }

    @media (max-width: 760px) {
      flex-direction: column;
    }
  `}
`;

type UploadProps = {
  width?: string;
  height?: string;
  borderColor: string;
  backgroundColor?: string;
};

export const ButtonUploadImage = styled.div<UploadProps>`
  ${({ width, height, borderColor, backgroundColor }) => css`
    gap: 20px;
    display: flex;
    cursor: pointer;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: ${width || "300px"};
    height: ${height || "200px"};
    border: 1px dashed ${borderColor};
    background-color: ${backgroundColor};

    @media (max-width: 760px) {
      width: 100%;
    }

    @media (max-width: 420px) {
      height: ${height || "160px"};
    }

    input {
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;
      z-index: 99;
      height: 100%;
      cursor: pointer;
      -moz-opacity: 0;
      position: absolute;
      filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    }
  `}
`;

type ColorProps = {
  borderColor: string;
  backgroundColor?: string;
};

export const PlusIcon = styled.div<ColorProps>`
  ${({ borderColor }) => css`
    width: 52px;
    height: 52px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${borderColor};

    svg {
      width: 60%;
      height: 60%;
    }
  `}
`;

type ImageProps = {
  image: string;
  width?: string;
  height?: string;
  borderColor: string;
  size: "cover" | "contain";
};

export const ImagePreview = styled.div<ImageProps>`
  ${({ size, width, height, image, borderColor }) => css`
    display: flex;
    border-radius: 24px;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: ${width || "300px"};
    height: ${height || "200px"};
    background: url(${image});
    background-size: ${size};
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid ${borderColor};

    @media (max-width: 760px) {
      width: 100%;
      align-self: flex-start;
    }

    @media (max-width: 420px) {
      height: ${height || "160px"};
    }
  `}
`;

export const Error = styled(Small)`
  display: flex;
  margin-top: 1rem;
`;
