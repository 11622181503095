import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import { TSections } from "../../../types";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  selected: TSections;
  setSelected: (index: TSections) => void;
}

const MobileMenu: React.FC<IProps> = ({ selected, setSelected }) => {
  const navigate = useNavigate();

  const { textColor, tertiaryColor } = Theme.useTheme();

  const onClickHandler = (section: TSections) => {
    setSelected(section);

    switch (section) {
      case "certified":
        navigate("/certificados");
        return;

      case "dashboard":
        navigate("/dashboard");
        return;

      case "profile":
        navigate("/perfil");
        return;

      case "statement":
        navigate("/vendas");
        return;

      default:
        navigate("/projetos");
        return;
    }
  };

  return (
    <S.Container backgroundColor={tertiaryColor}>
      <S.Sections>
        <S.Section
          key="projects"
          isSelected={selected === "projects"}
          onClick={() => onClickHandler("projects")}
        >
          <S.Icon>
            <AvailableIcons option="globe" color={textColor} />
          </S.Icon>
        </S.Section>

        <S.Section
          key="content"
          isSelected={selected === "certified"}
          onClick={() => onClickHandler("certified")}
        >
          <S.Icon>
            <AvailableIcons option="certified" color={textColor} />
          </S.Icon>
        </S.Section>

        <S.Section
          key="statement"
          isSelected={selected === "statement"}
          onClick={() => onClickHandler("statement")}
        >
          <S.Icon>
            <AvailableIcons option="statement" color={textColor} />
          </S.Icon>
        </S.Section>

        <S.Section
          key="dashboard"
          isSelected={selected === "dashboard"}
          onClick={() => onClickHandler("dashboard")}
        >
          <S.Icon>
            <AvailableIcons option="analytics" color={textColor} />
          </S.Icon>
        </S.Section>

        <S.Section
          key="profile"
          isSelected={selected === "profile"}
          onClick={() => onClickHandler("profile")}
        >
          <S.Icon>
            <AvailableIcons option="person" color={textColor} />
          </S.Icon>
        </S.Section>
      </S.Sections>
    </S.Container>
  );
};

export default MobileMenu;
