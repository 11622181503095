import styled, { css } from "styled-components";

import H4 from "../../atoms/Typography/H4";
import Small from "../../atoms/Typography/Small";
import ShowPasswordIcon from "../../icons/ShowPasswordIcon";
import { defaultTertiaryColor } from "../../../constants/colors";

type ContainerProps = {
  height?: string;
  borderRadius?: string;
  backgroundColor?: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ height, borderRadius, backgroundColor }) => css`
    width: 100%;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
    height: ${height && height};
    border-radius: ${borderRadius && borderRadius};
    background-color: ${backgroundColor && backgroundColor};

    input,
    textarea {
      flex-grow: 1;
      background-color: transparent;
    }
  `}
`;

export const LabelAndLimit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightBar = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

export const Brand = styled.div`
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const CharLimit = styled(Small)`
  font-size: 0.75rem;
`;

export const ErrorMessage = styled(Small)`
  font-size: 0.75rem;
`;

export const InputAndIcon = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

type InputContainerProps = {
  width: string;
  wrap: boolean;
  hasIcon: boolean;
  border?: boolean;
  backgroundColor?: string;
};

export const InputContainer = styled.div<InputContainerProps>`
  ${({ width, border, hasIcon, backgroundColor, wrap }) => css`
    padding: 8px;
    display: flex;
    width: ${width};
    overflow: hidden;
    align-items: center;
    border-radius: 12px;
    gap: ${hasIcon && "0.75rem"};
    justify-content: space-between;
    border: ${border && `1px solid ${defaultTertiaryColor}`};
    background-color: ${backgroundColor ? backgroundColor : "transparent"};

    @media (max-width: 640px) {
      flex-wrap: ${wrap && "wrap"};
    }
  `}
`;

export const BaseUrl = styled(H4)`
  flex-shrink: 0;
`;

export const CopyIcon = styled.div`
  width: 20px;
  height: 28px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const FixedPlaceholder = styled(H4)`
  flex-shrink: 0;
  margin-right: 8px;
  align-self: flex-start;
`;

export const Icons = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const PasswordIcon = styled(ShowPasswordIcon)`
  opacity: 0.5;
`;
